import { Component, OnInit } from '@angular/core';
import { DbService } from '../../services'
import { Router, ActivatedRoute } from '@angular/router'
import { Subscription } from 'rxjs'
import * as moment from 'moment'

@Component({
  selector: 'app-check',
  templateUrl: './check.component.html',
  styleUrls: ['./check.component.scss']
})
export class CheckComponent implements OnInit {

  routeSubscription: Subscription

  constructor(public db: DbService, private route: ActivatedRoute, private router: Router) {

    let cid = localStorage.getItem('cid')
    //console.log('cid', cid)

    this.routeSubscription = this.route.params.subscribe(params => {

      if (params.system) this.db.system = params.system

      if (this.db.system) this.getOrder(cid)

    })

  }

  ngOnInit(): void {
  }

  async getOrder(cid: string) {

    let orders = await <any>this.db.httpPost('find', { system: this.db.system, table: 'orders', condition: { cid: cid, delivered: false, orderDate: moment().format('YYYY-MM-DD') }, sort: { ts: -1 }, limit: 1, projection: { _id: 0, id: 1, orderNumber: 1 }, token: this.db.token })
    //console.log('Orders', orders)

    let ordernumber = orders.length ? (orders[0].orderNumber * 3 + 17) * 7 : '0'

    this.router.navigate([`/${this.db.system}/kvittering/1${ordernumber}`])

  }

}
