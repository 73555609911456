import { Component, OnInit, Input, ViewChild, TemplateRef } from '@angular/core';
import { DbService, LangService, MqttService } from '../../services'
import { Product, CartProduct } from '../../interfaces'
import { v4 as uuid } from 'uuid'
//import { NgxSmartModalService } from 'ngx-smart-modal';
import { faPlusCircle, faMinusCircle, faTimesCircle } from '@fortawesome/pro-light-svg-icons'
import { MatSnackBar } from '@angular/material/snack-bar'
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';

@Component({
  selector: 'app-product-item',
  templateUrl: './product-item.component.html',
  styleUrls: ['./product-item.component.scss']
})
export class ProductItemComponent implements OnInit {

  @Input() product: Product
  @ViewChild("dialogRef") dialogRef: TemplateRef<any>;
  @ViewChild("allergyRef") allergyRef: TemplateRef<any>;
  @ViewChild("infoRef") infoRef: TemplateRef<any>;
  @ViewChild("addonRef") addonRef: TemplateRef<any>;

  faPlusCircle = faPlusCircle; faMinusCircle = faMinusCircle; faTimesCircle = faTimesCircle

  addonArray = []
  showError1 = false

  cp: CartProduct

  productsToAdd: CartProduct[] = []

  quantityError = false

  mqttSerial = 0

  selectedImageId = ''

  constructor(public db: DbService, public lang: LangService, public snackBar: MatSnackBar, public dialog: MatDialog, private mqtt: MqttService) { }

  ngOnInit(): void {
  }

  del(n) {

    // Abort if product is not in cart
    if (!this.db.cart.products.filter(cp => cp.idProduct == this.product.id && !cp.idParent).length) return

    // Find last occurence of product in cart for deletion
    let lastOccurence = -1; let addonCount = 0
    
    this.db.cart.products.forEach((cp, index) => {
      
      if (cp.idProduct == this.product.id && !cp.idParent) {
        lastOccurence = index
      }

    })

    if (lastOccurence >= -1) {

      let cp = this.db.cart.products[lastOccurence]
      
      if (cp.quantity > 1) {

        this.db.cart.products.filter(_cp => _cp.idParent == cp.id).forEach(_cp => {
          let qp = _cp.quantity / cp.quantity
          _cp.quantity -= qp
        })

        cp.quantity -= 1

        this.db.productPipeHelper++

      } else {

        this.db.cart.products = this.db.cart.products.filter(cp => cp.idParent != this.db.cart.products[lastOccurence].id)
        this.db.cart.products.splice(lastOccurence, addonCount + 1)

        this.db.productPipeHelper++

      }

    }

  }

  add(n) {

    if (this.db.orderType.digitalMenuMode) return

    //console.log('Add', this.db.itemCount())

    if (this.product.isStock) {
      let quantityInCart = this.db.cart.products.filter(cp => cp.idProduct == this.product.id).reduce((acc, cp) => acc += cp.quantity, 0)
      //console.log('quantityInCart', quantityInCart)
      if (quantityInCart + n > this.product.stockLevel) return

      // Block for 10 minutes
      //if (this.product.stockLevel == 1) {
      //  this.product.blockedTo = moment().add(6, 'minutes').format('YYYY-MM-DD HH:mm:ss')
      //  this.mqtt.unsafePublish(`flow/block/${this.db.system}`, JSON.stringify({ idProduct: this.product.id, blockedTo: this.product.blockedTo }))
      //  this.db.httpPost('updateone', { system: this.db.system, table: 'products', condition: { id: this.product.id }, data: { blockedTo: this.product.blockedTo }, token: this.db.token })
      //}

    }

    if (this.db.orderType.maxItems && this.db.itemCount() >= this.db.orderType.maxItems) return this.snackBar.open(this.lang.l(313), '', { duration: 2000, panelClass: 'green-snackbar' })

    this.productsToAdd = []
    
    let cp: CartProduct = {
      id: uuid(),
      cid: this.db.cid,
      type: this.product.type,
      idProduct: this.product.id,
      idProductGroup: this.product.idProductGroup,
      name: this.product.name,
      bongText: this.product.bongText,
      otherLanguages: this.product.otherLanguages,
      quantity: n,
      unit: this.product.unit || '',
      idParent: '',
      price: this.db.getPrice(this.product.id),
      priceExcl: 0,
      ordPrice: this.db.getPrice(this.product.id, true),
      vatPercent: 0,
      totalAmount: 0,
      totalAmountExcl: 0,
      totalVatAmount: 0,
      discountAmount: 0,
      discountAmountExcl: 0,
      discountVatAmount: 0,
      discountPercent: 0,
      isTip: false,
      signature: '',
      isSotd: this.product.isSotd || false,
      isStock: this.product.isStock || false,
      isDish: this.product.isDish || false,
      idPrinters: this.product.idPrinters || [],
      remoteId: this.product.remoteId || '',
      remoteQuantity: this.product.remoteQuantity || 1,
      mealType: null
    }

    // Set mealType
    if (this.product.idMealType && this.db.mealTypes.length) {
      let mealType = this.db.mealTypes.find(mt => mt.id == this.product.idMealType)
      if (mealType) {
        cp.mealType = {
          id: mealType.id,
          name: mealType.name,
          sortorder: mealType.sortorder
        }
      }
    }


    this.productsToAdd.push(cp)

    if (this.product.addons.length || (this.product.localAddons && this.product.localAddons.length)) {
      this.addAddons()
    } else {
      this.addToCart()
    }

  }

  addAddons() {

    this.createAddonArray()
    
    // Proceed if only hidden items
    if (this.addonArray.filter(aa => aa.hidden).length == this.addonArray.length) {
      this.addonsDone()
      return
    }

    this.dialog.open(this.addonRef, { data: this.addonArray, position: { top: '20px' }, maxHeight: '95%', width: '95%' })
    
  }

  validateAddons = () => {


    this.addonArray.forEach(aa => {

      let checkedCount = aa.products.reduce((acc, ap) => acc += ap.quantity || (ap.chosen ? 1 : 0), 0)

      //console.log('checkedCount', checkedCount, aa.minItems, aa.maxItems)

      if ((aa.minItems && checkedCount < aa.minItems) || (aa.maxItems && checkedCount > aa.maxItems)) {

        this.quantityError = true

      }

    })

    if (this.quantityError) {
      
      setTimeout(() => {
        this.quantityError = false
      }, 2500)

      return

    }
    
    this.dialog.closeAll()
    this.addonsDone()

  }

  addonsDone() {

    //console.log(this.addonArray)

    let productsToAdd = []

    this.addonArray.forEach(aa => {

      let addonProducts = []
      
      // Add one of each product
      aa.products.filter(product => product.quantity || product.chosen).forEach(product => {

        let quantity = aa.multi ? product.quantity : 1

        for (let n = 0; n < quantity; n++) {

          let cp: CartProduct = { 
            id: uuid(),
            cid: this.db.cid,
            type: product.type,
            idProduct: product.id,
            idProductGroup: product.idProductGroup,
            name: product.name,
            bongText: product.bongText,
            otherLanguages: product.otherLanguages,
            quantity: 1,
            unit: product.unit || '',
            price: product.price,
            priceExcl: 0,
            ordPrice: product.ordPrice,
            vatPercent: 0,
            totalAmount: 0,
            totalAmountExcl: 0,
            totalVatAmount: 0,
            discountAmount: 0,
            discountAmountExcl: 0,
            discountVatAmount: 0,
            discountPercent: 0,
            idParent: this.productsToAdd[0].id,
            isTip: false,
            signature: '',
            isSotd: product.isSotd || false,
            isStock: product.isStock || false,
            isDish: false, // Not when product is addon
            idPrinters: product.idPrinters || [],
            remoteId: product.remoteId || '',
            remoteQuantity: product.remoteQuantity || 1,
            mealType: null
          }

          addonProducts.push(cp)

        }

      })

      // Check for free items
      if (aa.freeItems) {
        addonProducts = addonProducts.sort(this.db.sortBy('price'))
        for (let n = 0; n < aa.freeItems; n++) {
          addonProducts[n].price = 0
        }
      }

      // Join
      
      addonProducts.forEach(ap => {
        let signature = JSON.stringify({ idProduct: ap.idProduct, price: ap.price })
        let pta = productsToAdd.find(pta => pta.signature == signature)
        if (!pta) {
          productsToAdd.push({ signature: signature, product: ap })
        } else {
          pta.product.quantity++
        }
      })      



    })


    productsToAdd.forEach(pta => {
      this.productsToAdd.push(pta.product)
    })


    //addonProducts.forEach(ap => {
    //  this.productsToAdd.push(ap)
    //})

    this.addToCart()

  }

//  addonsDone1() {
//
//    this.addonArray.forEach(aa => {
//      
//      aa.products.filter(product => product.quantity || product.chosen).forEach(product => {
//      
//        let quantity = aa.multi ? product.quantity : 1
//
//        let price = product.price
//
//        if (aa.freeItems == 1 && aa.minItems == 1 && aa.maxItems == 1) price = 0
//
//        if (quantity) {
//      
//          let cp: CartProduct = { 
//            id: uuid(),
//            idProduct: product.id,
//            name: product.name,
//            bongText: product.bongText,
//            otherLanguages: product.otherLanguages,
//            quantity: quantity,
//            price: price,
//            priceExcl: 0,
//            vatPercent: 0,
//            totalAmount: 0,
//            totalAmountExcl: 0,
//            totalVatAmount: 0,
//            idParent: this.productsToAdd[0].id,
//            isTip: false,
//            signature: '',
//            isSotd: product.isSotd || false,
//            isDish: false // Not when product is addon
//          }
//
//          this.productsToAdd.push(cp)
//
//        }
//
//      })
//
//    })
//
//    this.addToCart()
//
//  }

  createAddonArray() {
  
    this.addonArray = []

    let combined = []

    this.product.addons.filter(pa => pa.active).forEach(pa => {
      let a = this.db.addons.find(a => a.id == pa.idAddon)
      if (a) {
        a.displayOrder = pa.displayOrder
        combined.push(a)
      }
    })

    if (this.product.localAddons) this.product.localAddons.filter(la => la.active).forEach(la => {
      combined.push(la)
    })

    combined = combined.sort((a, b) => { if (a.displayOrder < b.displayOrder) return -1; if (a.displayOrder > b.displayOrder) return 1; return 0; })

    combined.forEach(a => {

      let aa = {
        name: a.displayName || a.name,
        multi: a.multi,
        hidden: a.hidden,
        freeItems: a.freeItems,
        minItems: a.minItems,
        maxItems: a.maxItems,
        products: [],
        otherLanguages: a.otherLanguages
      }
        
      a.products.filter(ap => ap.active).forEach(ap => {

        let p = this.db.products.find(p => p.id == ap.idProduct && !p.blockedTo)

        if (p) {

          let price = ap.overridePrice || 0
          if (!price) price = this.db.getPrice(p.id) || 0
          if (ap.free) price = 0

          let ordPrice = this.db.getPrice(p.id, true) || 0

          let chosen = false
          let quantity = 0

          if (a.multi) {
            if (ap.defaultAmount) quantity = ap.defaultAmount
          } else {
            if (ap.default) chosen = true
          }
          
          aa.products.push({ 
            id: p.id,
            type: p.type,
            name: p.name,
            description: p.description,
            bongText: p.bongText,
            otherLanguages: p.otherLanguages,
            chosen: chosen,
            quantity: quantity,
            price: price,
            ordPrice: ordPrice,
            idPrinters: p.idPrinters || [],
            remoteId: p.remoteId || ''
          })
        }

      })

      this.addonArray.push(aa)

    })

  }

//  createAddonArrayOld() {
//  
//    this.addonArray = []
//
//    this.product.addons.filter(pa => pa.active).forEach(pa => {
//
//      let a = this.db.addons.find(a => a.id == pa.idAddon)
//
//      if (a) {
//
//        let aa = {
//          name: a.displayName || a.name,
//          multi: a.multi,
//          hidden: a.hidden,
//          freeItems: a.freeItems,
//          minItems: a.minItems,
//          maxItems: a.maxItems,
//          products: [],
//          otherLanguages: a.otherLanguages
//        }
//        
//        a.products.filter(ap => ap.active).forEach(ap => {
//
//          let p = this.db.products.find(p => p.id == ap.idProduct)
//          if (p) aa.products.push({ 
//            id: p.id,
//            name: p.name,
//            otherLanguages: p.otherLanguages,
//            chosen: ap.default || false,
//            quantity: 0,
//            price: this.db.getPrice(p.idProduct)
//          })
//
//        })
//
//        this.addonArray.push(aa)
//
//      }
//
//    })
//
//  }

  addToCart() {

    // Create signature for this product with addons to see if the same combination already exists in cart
    let signature = this.productsToAdd.map(pta => { return pta.idProduct }).join(',')
    this.productsToAdd.filter(pta => pta.idParent).forEach(pta => { signature += `-${pta.quantity}`})

    // If so...
    let existing = this.db.cart.products.find(cp => cp.signature == signature)
    if (existing) {

      existing.quantity += 1

      this.db.cart.products.filter(cp => cp.idParent == existing.id).forEach((cp, index) => {
        cp.quantity += this.productsToAdd[index + 1].quantity
      })
    
      this.mqttProductAdd()
      this.db.productPipeHelper++

      return

    }

    this.productsToAdd.forEach((pta, index) => {
      if (index == 0) pta.signature = signature
      this.db.cart.products.push(pta)
      this.db.sendGtmAddToCart(pta.idProduct, pta.name, pta.quantity, pta.price)
    })


    this.mqttProductAdd()
    this.db.productPipeHelper++

  }

  mqttProductAdd() {
    
    if (!this.db.configuration.interactiveTables || !this.db.cart.table) return

    this.mqtt.unsafePublish(`flow/table/${this.db.system}/${this.db.cart.table}`, JSON.stringify({ productName: this.product.name, firstName: this.db.cart.firstName, cid: this.db.cid, serial: this.db.productPipeHelper }))

  }

  clickProduct(a, ap) {
    ap.chosen = !ap.chosen
    this.checkBoxChange(false, a, ap)
  }

  checkBoxChange(e, a, ap) {

    let checkedCount = a.products.reduce((acc, ap) => acc += ap.chosen ? 1 : 0, 0)
    //console.log('Checked count', checkedCount)

    // Does not work as intended
//    if (a.maxItems && checkedCount > a.maxItems) {
//      
//      let removeItem = a.products.filter(_ap => _ap.chosen).find(_ap => _ap.id != ap.id)
//      if (removeItem) {
//        //console.log('remove', removeItem.name)
//        removeItem.chosen = false  
//      }
//
//    }

    if (a.maxItems == 1 && checkedCount > 1) {
      
      let removeItem = a.products.filter(_ap => _ap.chosen).find(_ap => _ap.id != ap.id)
      if (removeItem) {
        //console.log('remove', removeItem.name)
        removeItem.chosen = false  
      }

    }

//    if (checkedCount < a.minItems) {
//
//      let item = a.products.find(i => i.id == ap.id)
//      
//      setTimeout(() => {
//        if (item) item.chosen = true
//      }, 100)
//      
//    }

  }

  aiAdd(a, ap, q) {
    
    let checkedCount = a.products.reduce((acc, ap) => acc += ap.quantity || (ap.chosen ? 1 : 0), 0)
    
    // Check min and max values
    if (q == -1 && a.minItems && checkedCount - 1 < a.minItems) return
    if (q == 1 && a.maxItems && checkedCount + 1 > a.maxItems) return

    ap.quantity += q
    if (ap.quantity < 0) ap.quantity = 0

  }

  aiAddMulti(a, ap, q) {
    
    let checkedCount = a.products.reduce((acc, ap) => acc += ap.quantity || (ap.chosen ? 1 : 0), 0)
    
    // Check min and max values
    //if (q == -1 && a.minItems && checkedCount - 1 < a.minItems) return
    //if (q == 1 && a.maxItems && checkedCount + 1 > a.maxItems) return

    ap.quantity += q
    if (ap.quantity < 0) ap.quantity = 0
    if (ap.quantity > a.maxItems) ap.quantity = a.maxItems

  }

  allergyClick(a) {

    this.dialog.open(this.dialogRef, { data: a })

  }

  allergy2Click() {
    this.dialog.open(this.allergyRef, { data: this.product.idAllergies, width: '90%' })
  }

  infoClick() {
    this.selectedImageId = this.product.idImage
    this.dialog.open(this.infoRef, { data: this.product, maxWidth: '400px' })
  }

  imageClick() {

    if (this.db.imageClickAdd) {
      this.add(1)
    } else {
      this.infoClick()
    }

  }

  selectImage(id) {
    this.selectedImageId = id
  }


}
