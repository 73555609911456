import { Component, OnInit } from '@angular/core';
import { DbService, LangService } from '../../services'

@Component({
  selector: 'app-step600',
  templateUrl: './step600.component.html',
  styleUrls: ['./step600.component.scss']
})
export class Step600Component implements OnInit {

  constructor(public db: DbService) {

    if (this.db.stripeUrl) {
      window.location.assign(this.db.stripeUrl)
    }

  }

  ngOnInit(): void {
  }

}
