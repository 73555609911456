<div class="section" *ngIf="enableBizum">

    <form #payForm ngNoForm action="{{ db.bizumSession.url }}" method="post">
        <input type="hidden" id="Ds_SignatureVersion" name="Ds_SignatureVersion" value="{{ db.bizumSession.body.Ds_SignatureVersion}}" />
        <input type="hidden" id="Ds_MerchantParameters" name="Ds_MerchantParameters" value="{{ db.bizumSession.body.Ds_MerchantParameters }}" />
        <input type="hidden" id="Ds_Signature" name="Ds_Signature" value="{{ db.bizumSession.body.Ds_Signature }}"/>
        <input type="submit" value="Pay" />
    </form>

</div>
