import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'showDate'
})
export class ShowDatePipe implements PipeTransform {

  transform(dates: any[], showUnavailableDays: boolean): any[] {
    
    if (!showUnavailableDays) return dates.filter(d => d.available)
    
    return dates

  }

}
