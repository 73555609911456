import { Component, OnInit } from '@angular/core';
import { DbService, LangService } from '../../services'
import AdyenCheckout from '@adyen/adyen-web';
import { faTable } from '@fortawesome/pro-light-svg-icons';
import { MatSnackBar } from '@angular/material/snack-bar'

@Component({
  selector: 'app-step100',
  templateUrl: './step100.component.html',
  styleUrls: ['./step100.component.scss']
})
export class Step100Component implements OnInit {

  constructor(public db: DbService, public lang: LangService, public snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.adyenPay(this.db.adyenRequest)
  }

  async adyenPay(paymentMethodsResponse) {

    let clientKey = 'live_U5EHKSQU2BEWLJ7BRMTLPS246MSBUJFQ'
    if (['1854', 'storan'].includes(this.db.system)) clientKey = 'live_5OVE557RB5HUNBI2LEUBLUCTVMZCCJLY'
    if (this.db.system == 'demo1') clientKey = 'test_WRWKYSPAYBAVLCMY3KC7SVSDX4PDOIGV' 
    //if (this.db.system == 'stangs-mjardevi') clientKey = 'live_WBXFWYPXY5A43A6MA2D5PQJ45AC26M5B'

    let env = 'live'
    if (this.db.system == 'demo1') env = 'test'

    const configuration = {
      paymentMethodsResponse: paymentMethodsResponse, 
      clientKey: clientKey,
      locale: this.db.cart.activeLocale,
      environment: env,
      onSubmit: (state, dropin) => {

        //console.log('onSubmit', state)

        this.adyenMakePayment(state.data).then(response => {     

          //console.log('adyenMakePayment response',response)

          if (response.action) {
            dropin.handleAction(response.action);
          } else {
            this.adyenShowFinalResult(response);
          }
        }).catch(error => {
          //console.log('Error', error)
        })     
      },
      onAdditionalDetails: (state, dropin) => {

        //console.log('onAdditionalDetails', state)

        this.adyenMakeDetailsCall(state.data).then((response: any) => {

          //console.log('onAdditionalDetails response', response)

          if (response.action) {
            dropin.handleAction(response.action);
          } else {
            this.adyenShowFinalResult(response);
     
          }
        }).catch(error => {
          throw Error(error);
     
        })
      },
      paymentMethodsConfiguration: {
        card: {
          hasHolderName: true,
          holderNameRequired: true,
          enableStoreDetails: true,
          hideCVC: false, 
          name: 'Credit or debit card'
        },
        applepay: {
          amount: {
            value: this.db.cart.totalAmount * 100,
            currency: 'SEK'
          },
          countryCode: "SE",
          onSubmit: (state) => {
            this.adyenMakePayment(state.data).then(response => {
              this.adyenShowFinalResult(response);    
            }).catch(error => {
              throw Error(error);
            })
          }
        }
      }
     
    }

    // 3DS
    // https://docs.adyen.com/online-payments/3d-secure/native-3ds2/web-drop-in

    let options = {
      paymentMethodsConfiguration: {
        card: {
          hasHolderName: true,
          holderNameRequired: true,
          enableStoreDetails: true,
          name: 'Credit or debit card',
          billingAddressRequired: true
        },
        threeDS2: {
          challengeWindowSize: '05'
        }
      }
    }

    const checkout = await AdyenCheckout(configuration)
    const dropin = checkout.create('dropin', options).mount('#dropin-container')

  }

  async adyenMakePayment(data) {
    
    //console.log(1, data)

    let payRequest = {
      system: this.db.system,
      paymentMethod: data.paymentMethod,
      browserInfo: data.browserInfo,
      amount: this.db.cart.totalAmount * 100,
      idCart: this.db.cart.id,
      currency: 'SEK',
      shopperEmail: this.db.cart.email,
      billingAddress: data.billingAddress,
    }

    let result = await this.db.httpPost('adyen/pay', payRequest)
    //console.log('adyen/pay ==>>', result)

    return <any>result
    //return { action: true }

  }

  adyenShowFinalResult(response) {

    //console.log('adyenShowFinalResult', response)

    if (response?.resultCode == 'Refused') {
      this.snackBar.open('Betalning avbruten', '', { duration: 4000, panelClass: 'green-snackbar' })
      this.db.step = 50
    }

    if (response?.resultCode == 'Authorised') this.db.step = 60

  }

  async adyenMakeDetailsCall(data){

    //console.log('adyenMakeDetailsCall =>', data)

    let result = await this.db.httpPost('adyen/details', { system: this.db.system, details: data, idCart: this.db.cart.id })

    //console.log('adyenMakeDetailsCall result =>', result)

    return result

  }

}
