import { Component, OnInit } from '@angular/core';
import { DbService } from '../../services'
import { ActivatedRoute } from '@angular/router'
import { Subscription } from 'rxjs';
import { TeamSalesMember, TeamSalesProject } from '../../interfaces'
import { Order } from '../../interfaces'
import * as moment from 'moment'

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss']
})
export class OrdersComponent implements OnInit {

  routeSubscription: Subscription

  step = 1

  member: TeamSalesMember
  project: TeamSalesProject

  orders: Order[] = []

  logoUrl = ''
  logoImageUrl = ''

  constructor(private db: DbService, private route: ActivatedRoute) {

    if (window.location.hostname.includes('grillkol.se')) {
      this.db.system = 'grillkol'
      this.logoUrl = 'https://grillkol.se'
      this.logoImageUrl = 'https://media.floworder.se/grillkol/images/66307323-c559-410f-a669-24e455ac17f1.jpg'
    }

    if (window.location.hostname.includes('foreningskryddor.se')) {
      this.db.system = 'foreningskryddor'
      this.logoUrl = 'https://foreningskryddor.se'
      this.logoImageUrl = 'https://media.floworder.se/foreningskryddor/images/ef924b7d-fe5b-4615-b6fd-87a793f1b375.jpg'
    }

    if (window.location.hostname.includes('localhost')) {
      this.db.system = 'foreningskryddor'
      this.logoUrl = 'https://foreningskryddor.se'
      this.logoImageUrl = 'https://media.floworder.se/foreningskryddor/images/ef924b7d-fe5b-4615-b6fd-87a793f1b375.jpg'
    }

    
    this.routeSubscription = this.route.params.subscribe(params => {
      console.log(params)
      if (params.idProject && params.idMember) this.setup(params.idProject, params.idMember)  
    
    }) 

  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.routeSubscription.unsubscribe()
  }

  async setup(idTeamSalesProject, idMember) {

    //console.log(idTeamSalesProject, idMember)

    let condition = { 
      urlName: idTeamSalesProject, 
      $or: [
        { active: true },
        { closed: { $gt: moment().subtract(2, 'months').format('YYYY-MM-DD') }}
      ]
    }

    let projects: TeamSalesProject[] = await <any>this.db.httpPost('find', { system: this.db.system, table: 'teamsalesprojects', condition: condition, projection: { _id: 0, id: 1, name: 1 }, token: this.db.token })
    if (projects.length != 1) {
      this.step = 2
      return
    }
    this.project = projects[0]

    let members: TeamSalesMember[] = await <any>this.db.httpPost('find', { system: this.db.system, table: 'teamsalesmembers', condition: { code: idMember, active: true }, projection: { _id: 0, id: 1, firstName: 1, lastName: 1 }, token: this.db.token })
    if (members.length != 1) {
      this.step = 2
      return
    }
    this.member = members[0]

    this.step = 3

    let orderProjection = { 
      _id: 0, 
      orderNumber: 1,
      firstName: 1, 
      lastName: 1,
      phone: 1,
      email: 1,
      address1: 1,
      postCode: 1,
      city: 1,
      products: 1,
      totalAmount: 1,
      ts: 1
    }

    this.orders = await <any>this.db.httpPost('find', { system: this.db.system, table: 'orders', condition: { idProject: this.project.id, idMember: this.member.id }, projection: orderProjection, sort: { orderNumber: 1 }, token: this.db.token })
    if (!this.orders.length) {
      this.step = 4
      return
    }

    this.step = 5



  }  

}
