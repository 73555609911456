import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'

//import { NgxSmartModalModule } from 'ngx-smart-modal';

import { MqttModule, IMqttServiceOptions } from 'ngx-mqtt';

import { MatButtonModule } from '@angular/material/button'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatSnackBarModule } from '@angular/material/snack-bar'
import { MatInputModule } from '@angular/material/input'
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';

import {NgxMatIntlTelInputModule} from 'ngx-mat-intl-tel-input';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainComponent } from './components/main/main.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ProductItemComponent } from './components/product-item/product-item.component';
import { Step10Component } from './components/step10/step10.component';
import { Step20Component } from './components/step20/step20.component';
import { Step30Component } from './components/step30/step30.component';
import { Step40Component } from './components/step40/step40.component';
import { Step50Component } from './components/step50/step50.component';
import { Step60Component } from './components/step60/step60.component';
import { CategoriesComponent } from './components/categories/categories.component';
import { CatpipePipe } from './pipes/catpipe.pipe';
import { ProdpipePipe } from './pipes/prodpipe.pipe';
import { StickyComponent } from './components/sticky/sticky.component';
import { SanitizeHtmlPipe } from './pipes/sanitize-html.pipe';
import { RefPipe } from './pipes/ref.pipe';
import { ProductCountPipe } from './pipes/product-count.pipe';
import { VarDirective } from './directives/ng-var.directive';
import { CartCounterPipe } from './pipes/cart-counter.pipe';
import { ActivePipe } from './pipes/active.pipe';
import { LangPipe } from './pipes/lang.pipe';
import { LPipe } from './pipes/l.pipe';
import { PricePipe } from './pipes/price.pipe';
import { CartTotalPipe } from './pipes/cart-total.pipe';
import { GetProductPipe } from './pipes/get-product.pipe';
import { CartParentsPipe } from './pipes/cart-parents.pipe';
import { CartChildrenPipe } from './pipes/cart-children.pipe';
import { FromPricePipe } from './pipes/from-price.pipe';
import { SubTablePipe } from './pipes/sub-table.pipe';
import { SubObjPipe } from './pipes/sub-obj.pipe';
import { Step200Component } from './components/step200/step200.component';
import { SelectOrderTypesPipe } from './pipes/select-order-types.pipe';
import { MinToTimePipe } from './pipes/min-to-time.pipe';
import { ShowDatePipe } from './pipes/show-date.pipe';
import { Step100Component } from './components/step100/step100.component';
import { Step300Component } from './components/step300/step300.component';
import { OrdPricePipe } from './pipes/ord-price.pipe';
import { Step99Component } from './components/step99/step99.component';
import { AddressInputComponent } from './components/address-input/address-input.component';
import { Step400Component } from './components/step400/step400.component';
import { PayResultComponent } from './components/pay-result/pay-result.component';
import { Step500Component } from './components/step500/step500.component';
import { TeamMembersPipe } from './pipes/team-members.pipe';
import { CustomStylePipe } from './pipes/custom-style.pipe';
import { KvitteraComponent } from './components/kvittera/kvittera.component';
import { PayButtonsPipe } from './pipes/pay-buttons.pipe';
import { CheckComponent } from './components/check/check.component';
import { Nl2brPipe } from './pipes/nl2br.pipe';
import { Step600Component } from './components/step600/step600.component';
import { Step110Component } from './components/step110/step110.component';
import { UpdateAddressComponent } from './components/update-address/update-address.component';
import { ShowFromLabelPipe } from './pipes/show-from-label.pipe';
import { OrdersComponent } from './components/orders/orders.component';
import { MemberRegisterComponent } from './components/member-register/member-register.component';

export const MQTT_SERVICE_OPTIONS: IMqttServiceOptions = {
  hostname: 'db10.bokad.se',
  port: 9002,
  protocol: 'wss',
  path: '',
  username: 'printer',
  password: 'retnirp'
};

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    ProductItemComponent,
    Step10Component,
    Step20Component,
    Step30Component,
    Step40Component,
    Step50Component,
    Step60Component,
    CategoriesComponent,
    CatpipePipe,
    ProdpipePipe,
    StickyComponent,
    SanitizeHtmlPipe,
    RefPipe,
    ProductCountPipe,
    VarDirective,
    CartCounterPipe,
    ActivePipe,
    LangPipe,
    LPipe,
    PricePipe,
    CartTotalPipe,
    GetProductPipe,
    CartParentsPipe,
    CartChildrenPipe,
    FromPricePipe,
    SubTablePipe,
    SubObjPipe,
    Step200Component,
    SelectOrderTypesPipe,
    MinToTimePipe,
    ShowDatePipe,
    Step100Component,
    Step300Component,
    OrdPricePipe,
    Step99Component,
    AddressInputComponent,
    Step400Component,
    PayResultComponent,
    Step500Component,
    TeamMembersPipe,
    CustomStylePipe,
    KvitteraComponent,
    PayButtonsPipe,
    CheckComponent,
    Nl2brPipe,
    Step600Component,
    Step110Component,
    UpdateAddressComponent,
    ShowFromLabelPipe,
    OrdersComponent,
    MemberRegisterComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule, ReactiveFormsModule,
    FontAwesomeModule,
    //NgxSmartModalModule.forRoot(),
    MatButtonModule, MatCheckboxModule, MatSnackBarModule, MatInputModule, MatDialogModule, MatIconModule, MatMenuModule, MatSelectModule, MatFormFieldModule,
    MqttModule.forRoot(MQTT_SERVICE_OPTIONS),
    NgxMatIntlTelInputModule
  ],
  providers: [],
  bootstrap: [AppComponent]
  
})
export class AppModule { }
