<div class="item" [style.backgroundColor]="db.configuration.boxColor" [style.color]="db.configuration.boxTextColor">

    <!-- Item heading -->
    <div *ngIf="db.showProductnameAbove" class="itemheading" [style.font-family]="db.fonts[0]" [style]="1027 | customStyle : db.stylePipeHelper" [style]="db.compact ? 'min-height: 40px;' : ''" (click)="add(1)">{{ product | lang : 'name' : lang.ph }}</div>

    <!-- Price and stock level -->
    <div *ngIf="db.showProductnameAbove" style="display: flex; justify-content: center; align-items: center;">
        <div class="price" [style.font-family]="db.fonts[1]" (click)="add(1)">
            <span *ngIf="(product.id | price)" >
                {{ product.id | price | number : lang.numberFormat : lang.activeLocale }} {{ db.configuration.currency }}<span *ngIf="product.unit"> / {{product.unit}}</span>
                <span *ngIf="(product.id | ordPrice)"> ({{ product.id | ordPrice | number : lang.numberFormat : lang.activeLocale }} {{ db.configuration.currency }})</span>
            </span>
            <span *ngIf="!(product.id | price)"><span *ngIf="product | showFromLabel">{{ 245 | l : lang.ph }} </span>{{ product | fromPrice | number : lang.numberFormat : lang.activeLocale }} {{ db.configuration.currency }}<span *ngIf="product.unit"> / {{product.unit}}</span></span>
        </div>
        <!--<div *ngIf="product.isStock" class="price">, {{ 387 | l : lang.ph }}: {{product.stockLevel}}</div>-->
    </div>

    <!-- Out of stock -->
    <div *ngIf="product.isStock && product.stockLevel <= 0" class="soldout">Sold out</div>

    <!-- Image -->
    <div *ngIf="product.idImage" (click)="imageClick()" class="imgdiv">
        <!--<img [src]="'https://db20.bokad.se/' + db.imageFolder + '/' + db.system + '/' + product.idImage" class="img" [style]="db.compact ? 'maxWidth: 100%' : ''"/>-->
        <img [src]="'https://media.floworder.se/' + db.system + '/images/' + product.idImage" class="img" [style]="db.compact ? 'maxWidth: 100%' : ''"/>
    </div>

    <!-- Item heading -->
    <div *ngIf="!db.showProductnameAbove" class="itemheading" [style.font-family]="db.fonts[0]" [style]="1027 | customStyle : db.stylePipeHelper" [style]="db.compact ? 'min-height: 40px;' : ''" (click)="add(1)">{{ product | lang : 'name' : lang.ph }}</div>

    <!-- Price and stock level -->
    <div *ngIf="!db.showProductnameAbove" style="display: flex; justify-content: center; align-items: center;">
        <div class="price" [style.font-family]="db.fonts[1]" (click)="add(1)">
            <span *ngIf="(product.id | price)" >
                {{ product.id | price | number : lang.numberFormat : lang.activeLocale }} {{ db.configuration.currency }}<span *ngIf="product.unit"> / {{product.unit}}</span>
                <span *ngIf="(product.id | ordPrice)">({{ product.id | ordPrice | number : lang.numberFormat : lang.activeLocale }} {{ db.configuration.currency }})</span>
            </span>
            <span *ngIf="!(product.id | price)"><span *ngIf="product | showFromLabel">{{ 245 | l : lang.ph }} </span>{{ product | fromPrice | number : lang.numberFormat : lang.activeLocale }} {{ db.configuration.currency }}<span *ngIf="product.unit"> / {{product.unit}}</span></span>
        </div>
        <!--<div *ngIf="product.isStock" class="price">, {{ 387 | l : lang.ph }}: {{product.stockLevel}}</div>-->
    </div>    

    <!-- Item description -->
    <div *ngIf="!db.compact && db.showDescription" class="itemtext" [style.font-family]="db.fonts[2]" [style]="1022 | customStyle : db.stylePipeHelper" [innerHTML]="(product | lang : 'description' : lang.ph)  | nl2br | sanitizeHtml" (click)="add(1)"></div>

    <!-- Dummy div to fill space -->
    <div style="flex:1"></div>

    <!-- Allergies -->
    <div *ngIf="!db.configuration.addButtonMode && product.idAllergies &&!db.compact" class="allergydiv">
        <div *ngFor="let a of product.idAllergies | subTable : db.allergies" class="allergyitem" (click)="allergyClick(a)">
            <!--<img [src]="'https://db20.bokad.se/images/' + db.system + '/' + a.idImage" class="allergyimg"/>-->
            <img [src]="'https://media.floworder.se/' + db.system + '/images/' + a.idImage" class="allergyimg"/>
        </div>
    </div>

    <!-- Info in compact mode-->
    <div *ngIf="db.compact && db.showInfoButton" style="text-align: center; padding: 10px 0;">
        <button mat-raised-button style="width: 100%;" [style.backgroundColor]="db.configuration.allergyButtonColor" [style.color]="db.configuration.allergyButtonTextColor" (click)="infoClick()">{{ 385 | l : lang.ph }}<span *ngIf="product.idAllergies && product.idAllergies.length"> **</span></button>
    </div>

    <!-- Allergies in compact mode 
    <div *ngIf="db.compact && product.idAllergies && product.idAllergies.length" style="text-align: center; padding: 5px 0;">
        <button mat-raised-button style="width: 100%;" [style.backgroundColor]="db.configuration.allergyButtonColor" [style.color]="db.configuration.allergyButtonTextColor" (click)="allergy2Click()">{{ 209 | l : lang.ph }}</button>
    </div> -->

    <div *ngIf="!db.orderType.digitalMenuMode">

        <!-- Select quantity -->
        <div *ngIf="!db.configuration.addButtonMode && db.showQuantitySelector" class="itemantal noselect" [style.font-family]="db.fonts[0]">
            <span *ngIf="!db.compact" class="ri">{{ 207 | l : lang.ph }}</span>
            <span class="ri po" (click)="del(1)">-</span>
            <span class="ri">{{ product.id | productCount : db.productPipeHelper }}</span>
            <span class="po" (click)="add(1)">+</span>
        </div>
    
        <div *ngIf="db.configuration.addButtonMode && db.configuration.addButtonMode == 1" style="display: flex; justify-content: space-between; margin-bottom: 10px">
        
            <div *ngIf="product.idAllergies && product.idAllergies.length" style="padding-right: 20px;">
                <button mat-raised-button style="width: 100%;" [style.backgroundColor]="db.configuration.allergyButtonColor" [style.color]="db.configuration.allergyButtonTextColor" (click)="allergy2Click()">{{ 209 | l : lang.ph }}</button>
            </div>
        
            <div style="flex: 1 1 auto; display: flex; align-items: center;">
                <div style="flex: 1 1 auto"><button style="width: 100%" mat-raised-button [style.backgroundColor]="db.configuration.addButtonColor" [style.color]="db.configuration.addButtonTextColor" (click)="del(1)"><fa-icon [icon]="faMinusCircle" style="font-size: 24px;"></fa-icon></button></div>
                <div style="display: inline-block; padding: 0 20px; font-size: 20px;" [style.color]="db.configuration.addButtonTextColor">{{ product.id | productCount : db.productPipeHelper }}</div>
                <div style="flex: 1 1 auto"><button style="width: 100%" mat-raised-button [style.backgroundColor]="db.configuration.addButtonColor" [style.color]="db.configuration.addButtonTextColor" (click)="add(1)"><fa-icon [icon]="faPlusCircle" style="font-size: 24px;"></fa-icon></button></div>
            </div>    
        
        </div>

    </div>
    
    <!-- Spacer -->
    <div style="height: 10px;"></div>

</div>

<ng-template #dialogRef let-allergy>
    <div class="a1">{{ allergy | lang : 'name' : lang.ph }}</div>
    <div class="a2">{{ allergy | lang : 'description' : lang.ph }}</div>
</ng-template>

<ng-template #allergyRef let-allergies>
    
    <div *ngFor="let allergy of allergies | subTable : db.allergies" style="display: flex; align-items: center; margin: 5px 0" mat-dialog-close>

        <div>
            <!--<img [src]="'https://db20.bokad.se/images/' + db.system + '/' + allergy.idImage" class="allergyimg"/>-->
            <img [src]="'https://media.floworder.se/' + db.system + '/images/' + allergy.idImage" class="allergyimg"/>
        </div>
        <div>
            <div class="a11">{{ allergy | lang : 'name' : lang.ph }}</div>
            <div class="a22">{{ allergy | lang : 'description' : lang.ph }}</div>
        </div>

    </div>
</ng-template>

<ng-template #infoRef let-product>

    <!-- Close icon -->
    <div style="text-align: right; padding-bottom: 5px;" mat-dialog-close matDialogTitle>
        <fa-icon [icon]="faTimesCircle" style="font-size: 24px;"></fa-icon>
    </div>
    
    <div mat-dialog-content>

        <!-- Image -->
        <div *ngIf="product.idImage">
            <img [src]="'https://media.floworder.se/' + db.system + '/images/' + selectedImageId + '?i=' + selectedImageId" style="width: 100%; max-width: 400px;"/>
        </div>

        <!-- Image Selector -->
        <div *ngIf="product.idImages && product.idImages.length">
            <div class="extraimage" (click)="selectImage(product.idImage)">
                <img [src]="'https://media.floworder.se/' + db.system + '/thumbnails/' + product.idImage" style="width: 100%;"/>
            </div>
            <div *ngFor="let imageId of product.idImages" class="extraimage" (click)="selectImage(imageId)">
                <img [src]="'https://media.floworder.se/' + db.system + '/thumbnails/' + imageId" style="width: 100%;"/>
            </div>
        </div>

        <!-- Product name -->
        <div style="padding: 10px 0; font-size: 20px; font-weight: 700;">{{ product | lang : 'name' : lang.ph }}</div>

        <!-- Price -->
        <div style="padding: 5px 0; font-size: 16px; font-weight: 700;">
            <!--{{ product.id | price | number : lang.numberFormat : lang.activeLocale }} {{ db.configuration.currency }}-->
            <span *ngIf="!(product.id | price)">{{ 245 | l : lang.ph }} {{ product | fromPrice | number : lang.numberFormat : lang.activeLocale }} {{ db.configuration.currency }}<span *ngIf="product.unit"> / {{product.unit}}</span></span>
        </div> 

        <!-- Product description -->
        <div style="padding: 10px 0; font-size: 16px; max-width: 400px;" [innerHTML]="(product | lang : 'description' : lang.ph) | nl2br | sanitizeHtml"></div>

        <!-- Product description2 -->
        <div style="padding: 10px 0; font-size: 16px; max-width: 400px;" [innerHTML]="(product | lang : 'description2' : lang.ph) | nl2br | sanitizeHtml"></div>

        <!-- Allergies -->
        <div *ngFor="let allergy of product.idAllergies | subTable : db.allergies" style="display: flex; align-items: center; margin: 5px 0">

            <div>
                <!--<img [src]="'https://db20.bokad.se/images/' + db.system + '/' + allergy.idImage" class="allergyimg"/>-->
                <img [src]="'https://media.floworder.se/' + db.system + '/images/' + allergy.idImage" class="allergyimg"/>
            </div>
            <div>
                <div class="a11">{{ allergy | lang : 'name' : lang.ph }}</div>
                <div class="a22">{{ allergy | lang : 'description' : lang.ph }}</div>
            </div>
    
        </div>        

    </div>

    <!-- Add to cart -->
    <div style="margin: 10px 0" mat-dialog-actions>
        <button mat-raised-button color="primary" (click)="add(1)" style="width: 100%; max-width: 400px;" [disabled]="product.isStock && product.stockLevel <= 0" mat-dialog-close>{{ 441 | l : lang.ph }}</button>
    </div>

    
</ng-template>

<ng-template #addonRef let-addonArray>

    <div *ngIf="addonArray" class="addoncontent">
        
        <button mat-icon-button class="close-button" [mat-dialog-close]="true">
            <mat-icon class="close-icon" color="warn">close</mat-icon>
        </button>
        
        
        <h2 mat-dialog-title>{{ 124 | l : lang.ph }}</h2>

        <mat-dialog-content>

        <div *ngFor="let a of addonArray" style="text-align: left; margin-bottom: 10px" [style.display]="a.hidden ? 'none' : 'block'" class="noselect">

            <div class="addonheader">{{ a | lang : 'name' : lang.ph }}</div>

                <div class="addonsdiv">

                    <div *ngFor="let ap of a.products" class="addonitem">

                        <div *ngIf="a.multi" class="applusminus" (click)="aiAddMulti(a, ap, -1)">

                            <div *ngIf="a.multi" style="display: flex; align-items: center; height: 100%">
                              <div style="color: white"><fa-icon [icon]="faMinusCircle"></fa-icon></div>
                            </div>
          
                        </div>

                        <!-- Vänster -->
                        <div *ngIf="!a.multi" style="padding-right: 5px;">
                            <input type="checkbox" [(ngModel)]="ap.chosen" (change)="checkBoxChange($event, a, ap)">
                        </div>

                        <!-- Mitten -->
                        <div style="flex-grow: 1;" [class.aptext]="a.multi" (click)="clickProduct(a, ap)">
                            <div class="apname" [class.qsel]="ap.quantity"><span *ngIf="ap.quantity" style="padding-right: 6px">{{ap.quantity}} st</span>{{ ap | lang : 'name' : lang.ph }}</div>
                            <div *ngIf="ap.description" class="apsubtext">{{ ap | lang : 'description' : lang.ph }}</div>
                        </div>

                        <!--
                        <div *ngIf="ap.id | price" style="text-align: right; flex: 0 0 60px;" [class.aptext]="a.multi" [class.qsel]="ap.quantity"><span>{{ (ap.id | price) * (ap.quantity || 1) }} {{db.configuration.currency}}</span></div>                
                        -->
                        <div style="text-align: right; flex: 0 0 auto; padding-left: 5px;" [class.aptext]="a.multi" [class.qsel]="ap.quantity">
                            <!--<span *ngIf="ap.id | price">{{ (ap.id | price) * (ap.quantity || 1) }} {{db.configuration.currency}}</span>-->
                            <span *ngIf="ap.price">{{ (ap.price) * (ap.quantity || 1) | number : lang.numberFormat : lang.activeLocale }} {{db.configuration.currency}}</span>
                        </div>                


                        <!-- Höger -->
                        <div *ngIf="a.multi" class="applusminus" (click)="aiAddMulti(a, ap, 1)">
                            <div style="display: flex; align-items: center; height: 100%">
                            <div style="color: white"><fa-icon [icon]="faPlusCircle"></fa-icon></div>
                        </div>

                    </div>

                </div>
  
            </div>

        </div>

        
        <div *ngIf="quantityError" class="error">{{ 254 | l : lang.ph }}</div>
        
        <div style="padding-top: 20px; text-align: center;">
            <button mat-raised-button color="primary" (click)="validateAddons()">{{ 212 | l : lang.ph }}</button>
        </div>

        </mat-dialog-content>

        <!--<pre>{{ addonArray | json }}</pre>-->

  
    </div>


</ng-template>