import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'teamMembers'
})
export class TeamMembersPipe implements PipeTransform {

  transform(members: any[], idTeam: string, pipeHelper: number): unknown {
    console.log(1, members, idTeam, )
    console.log(2, idTeam )
    console.log(3, members.filter(m => m.idTeam == idTeam) )
    return members.filter(m => m.idTeam == idTeam)
  }

}
