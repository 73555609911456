import { Component, OnInit } from '@angular/core';
import { DbService, LangService, MqttService } from '../../services'
import { Subscription } from 'rxjs'
import { ActivatedRoute } from '@angular/router'
import { v4 as uuid } from 'uuid'
import * as moment from 'moment'
//import { NgxSmartModalService } from 'ngx-smart-modal';
import { RRule, RRuleSet, rrulestr } from 'rrule'
import { MatSnackBar } from '@angular/material/snack-bar'
import { MatDialog } from '@angular/material/dialog';
import { faUserFriends } from '@fortawesome/pro-light-svg-icons'
import { Customer } from 'src/app/interfaces';
import { DeviceDetectorService } from 'ngx-device-detector'
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {

  faUserFriends = faUserFriends

  debug = false
  showConcent = false

  routeSubscription: Subscription
  mqttSubscription: Subscription
  avoidLoopOfDeath = 0

  constructor(public db: DbService, public lang: LangService, private route: ActivatedRoute, private mqtt: MqttService, public snackBar: MatSnackBar, public dialog: MatDialog, private deviceService: DeviceDetectorService, private meta: Meta, private title: Title) {

    // Wait for route info, then login
    this.routeSubscription = this.route.params.subscribe(params => {

      if (params.qrCode) this.db.qrCode = params.qrCode
      if (params.link) this.db.link = params.link
      if (params.idCustomer) this.db.idCustomer = params.idCustomer
      if (params.idOrderType) this.db.idOrderType = params.idOrderType

      //if (params.projectName) this.setupProject(params.system, params.projectName, params.memberName)
      
      if (window.location.hostname.includes('grillkol.se')) {
        this.setupProject('grillkol', params.idProject, params.idMember)
        this.login('grillkol')
        this.meta.addTag({ name: 'og:title', content: 'Grillkol.se' });
        this.meta.addTag({ name: 'og:image', content: 'https://media.floworder.se/grillkol/images/8fcab3e8-01f4-4c07-8698-ec356ac0a2a3.jpg'})
        this.title.setTitle('Grillkol.se');
      }

      if (window.location.hostname.includes('foreningskryddor.se')) {

        this.meta.addTag({ name: 'og:title', content: 'Foreningskryddor.se' });
        this.meta.addTag({ name: 'og:image', content: 'https://media.floworder.se/foreningskryddor/images/060da814-97f3-4335-91fa-8abb499e5a56.jpg'})

        this.title.setTitle('Foreningskryddor.se');

        let favIcon: HTMLLinkElement = document.querySelector('#appIcon')
        favIcon.href = '/assets/favicon-f.ico'

        this.setupProject('foreningskryddor', params.idProject, params.idMember)
        this.login('foreningskryddor')
        
      }

      if (window.location.hostname.includes('localhost')) {
        //this.setupProject('foreningskryddor', params.idProject, params.idMember)
        //this.login('foreningskryddor')
        this.setupProject('grillkol', params.idProject, params.idMember)
        this.login('grillkol')
      }

      //if (params.memberName) {
      //  console.log(params.memberName)
      //  this.db.memberName = params.memberName
      //}      

      //const qr = this.route.snapshot.queryParamMap.get('qr')
      //if (qr) {
      //  this.db.qrCode = qr
      //  this.db.cart.qrCode = qr
      //}
      
      //const link = this.route.snapshot.queryParamMap.get('link')
      //if (link) this.db.link = link

      //const c = this.route.snapshot.queryParamMap.get('c')
      //if (c) this.db.idCustomer = c

      // Check for Table Token id in local storage
      //let _ttid = localStorage.getItem('ttid')
      //if (_ttid) this.db.ttid = _ttid

      // Check for Table Token id in Query string
      //const ttid = this.route.snapshot.queryParamMap.get('ttid')
      //if (ttid) this.db.ttid = ttid
      
      //if (params.system) this.login(params.system)

      //this.db.idOrderType = '795fd6bb-f181-4d78-bf5a-877b09c5d096'
      //this.login('grillkol')

    })

  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.routeSubscription.unsubscribe()
    //this.mqttSubscription.unsubscribe()
  } 

  async login(system) {

    this.db.system = system

    // Client id
    let cid = localStorage.getItem('cid')
    if (!cid || cid.length != 36) {

      // No or invalid cid, create.
      cid = uuid()
      localStorage.setItem('cid', cid)
      await this.db.httpPost('insertone', { system: 'global', table: 'cidinfo', data: { id: uuid(), cid: cid, createdts: moment().format('YYYY-MM-DD HH:mm:ss') }, token: this.db.token })

    } else {
      
      // Cid found, check if it exists in db
      let _cid: any = await this.db.httpPost('findone', { system: 'global', table: 'cidinfo', condition: { cid: cid }, token: this.db.token })

      if (_cid) {

        //console.log('Cid from db', _cid)

        // Get customer info if available
        //this.db.cart.firstName = _cid.firstName || ''
        //this.db.cart.lastName = _cid.lastName || ''
        //this.db.cart.email = _cid.email || ''
        //this.db.cart.phone = _cid.phone || ''
        //if (this.db.cart.phone.length == 10) this.db.cart.phone = `+46${this.db.cart.phone.substring(1)}`

      } else {

        // Insert to db
        await this.db.httpPost('insertone', { system: 'global', table: 'cidinfo', data: { id: uuid(), cid: cid, createdts: moment().format('YYYY-MM-DD HH:mm:ss') }, token: this.db.token })

      }

    }
    this.db.cid = cid 

    // Update cid with device info
    await this.db.httpPost('updateone', { system: 'global', table: 'cidinfo', condition: { cid: cid }, data: { deviceInfo: this.db.getDeviceInfo(), lastSeen: moment().format('YYYY-MM-DD HH:mm:ss') }, token: this.db.token })

    // Create cid visit to this system
    await this.db.httpPost('insertone', { system: 'global', table: 'cidvisit', data: { id: uuid(), cid: cid, createdts: moment().format('YYYY-MM-DD HH:mm:ss'), system: this.db.system }, token: this.db.token })

    let result: any = await this.db.httpPost('login', { system: system, username: 'web', password: 'webwebweb', cid: this.db.cid })
    //console.log('Login result', result)

    if (!result.err) {

      this.db.token = result.id
      localStorage.setItem('token', this.db.token)

      this.db.isLoggedIn = true

      this.lang.getLanguages()
      this.setup()

      //this.router.navigate(['/start'])

    }

  }

  async setup() {

    // Set workday
    this.db.workday = moment().format('YYYY-MM-DD')
    if (moment().hour() < 4) this.db.workday = moment().subtract(1, 'day').format('YYYY-MM-DD')

    // Get webdata package from server
    let result: any = await this.db.httpPost('webdata', { system: this.db.system })

    if (this.debug) console.log('webdata', result)

    if (!result || result.err) return
    
    this.db.products = result.products
    this.db.categories = result.categories
    this.lang.languages = result.languages
    this.lang.translations = result.translations
    this.db.orderTypes = result.orderTypes
    this.db.qrRanges = result.qrRanges
    this.db.addons = result.addons
    this.db.calendars = result.calendars
    this.db.vats = result.vats
    this.db.productGroups = result.productGroups
    this.db.configuration = result.configuration
    this.db.allergies = result.allergies
    this.db.paymentTypes = result.paymentTypes

    this.db.checkBlockedCategories()

    // Get custom styles
    this.db.customStyles = await <any>this.db.httpPost('find', { system: this.db.system, table: 'customstyles', condition: { active: true }, projection: { _id: 0, nr: 1, style: 1 }, token: this.db.token })
    this.db.stylePipeHelper++

    this.db.mealTypes = await <any>this.db.httpPost('find', { system: this.db.system, table: 'mealtypes', condition: { }, projection: { _id: 0, id: 1, name: 1, sortorder: 1 }, token: this.db.token })
    //console.log('Mealtypes', this.db.mealTypes)

    // Get default pricelist id
    let priceList = await <any>this.db.httpPost('findone', { system: this.db.system, table: 'pricelists', condition: { default: true }, token: this.db.token })
    if (priceList) this.db.idDefaultPriceList = priceList.id

    // Get client messages
    this.db.clientMessages = await <any>this.db.httpPost('find', { system: this.db.system, table: 'clientmessages', condition: { showTo: { $gt: moment().format('YYYY-MM-DD HH:mm') } }, token: this.db.token })

    // Setup GTM
    this.db.gtmId = this.db.configuration.gtmId || 'GTM-57ZF78K'
    this.db.setupGtm()

    // Parse terms
    if (this.db.configuration.terms) this.db.configuration.terms = this.db.configuration.terms
    .replace(/{name}/ig, this.db.configuration.name || '')
    .replace(/{legalName}/ig, this.db.configuration.legalName || '')
    .replace(/{phone}/ig, this.db.configuration.phone || '')
    .replace(/{adr1}/ig, this.db.configuration.adr1 || '')
    .replace(/{adr2}/ig, this.db.configuration.adr2 || '')
    .replace(/{email}/ig, this.db.configuration.email || '')
    .replace(/{businessId}/ig, this.db.configuration.businessId || '')

    //if (this.db.categories.length) {
    //  let c = this.db.categories[0]
    //  this.db.selectedCategories.push(c.id)
    //  this.db.selCatProdIds = c.products.filter(cp => cp.active).map(cp => cp.idProduct )
    //}

    this.db.categories.filter(c => !c.idParent).forEach(c => { this.setCategoryLevel(c, 0) })
    //console.log(this.db.categories)

    //this.createCategoryTree()

    this.db.categoryPipeHelper++
    if (this.db.configuration.font1) {
      this.db.fonts = []
      this.db.fonts.push(this.db.configuration.font1)
      this.db.fonts.push(this.db.configuration.font2)
      this.db.fonts.push(this.db.configuration.font3)
    }
    this.db.getFonts()

    // Set language

    // 1. Check Local Storage
    let languageCode = localStorage.getItem('languageCode')
    //console.log('1>>', languageCode)
    if (languageCode) {
      let l = this.lang.languages.find(l => l.languageCode == languageCode)
      if (l) {
        this.lang.setLang(languageCode) 
        this.lang.setLocale(l.locale)  
      }
    }

    // 2. Check browser language
    let navigatorLocale = navigator.language
    //console.log('2>>', navigatorLocale, this.lang.languages)
    if (!this.lang.activeLanguageCode) {
      let l = this.lang.languages.find(l => l.languageCode == navigatorLocale)
      if (l) {
        this.lang.setLang(l.languageCode)
        this.lang.setLocale(l.locale)
      }
    }

    // 3. Use default language
    let defaultLanguage = this.lang.languages.find(l => l.default)
    if (defaultLanguage) {
      this.lang.defaultLanguageCode = defaultLanguage.languageCode
      if (!this.lang.activeLanguageCode) {
        this.lang.setLang(defaultLanguage.languageCode)
        this.lang.setLocale(defaultLanguage.locale)
      }
    }

///    // 1. Check Local Storage
///    let localStoragelanguageCode = localStorage.getItem('languageCode')
///
///    // 2. Check browser language
///    let browserLanguageCode = navigator.language.substring(0, 2).toLocaleLowerCase()
///
///    // 3. Get default language
///    let defaultLanguage = this.lang.languages.find(l => l.default)
///    let defaultLanguageCode = defaultLanguage.languageCode
///
///    //console.log('localStorage', localStoragelanguageCode, 'browser', browserLanguageCode, 'default', defaultLanguageCode)
///
///    let languageCode = localStoragelanguageCode || browserLanguageCode || defaultLanguageCode
///
///    // Try to get this lang
///    let l = this.lang.languages.find(l => l.languageCode == languageCode)
///    
///    // Else try with browser language
///    if (!l) l = this.lang.languages.find(l => l.languageCode == browserLanguageCode)
///
///    // Else go for default language
///    if (!l) l = this.lang.languages.find(l => l.languageCode == defaultLanguageCode)
///
///    // No options left
///    if (!l) return
///
///    // Set language
///    this.lang.defaultLanguageCode = l.languageCode
///    this.lang.setLang(l.languageCode)
///    this.lang.setLocale(l.locale)    

    if (!this.lang.activeLanguageCode || !this.lang.defaultLanguageCode) return // Somthing is wrong in language setup

    // Rounding
    let roundingDigits = this.db.configuration.roundingDigits || 0
    this.lang.numberFormat = `1.${roundingDigits}-${roundingDigits}`
    
    // Setup tip
    if (this.db.configuration.tipPercentages) this.db.configuration.tipPercentages.split(',').forEach(tp => { this.db.tipPercentages.push(+tp) })
    if (this.db.configuration.tipAmounts) this.db.configuration.tipAmounts.split(',').forEach(ta => { this.db.tipAmounts.push(+ta) })

    // Team sales
    //if (this.db.configuration.teamSales) {
    //  this.db.teams = await <any>this.db.httpPost('find', { system: this.db.system, table: 'teamsalesteams', condition: { active: true }, projection: { id: 1, name: 1 }, sort: { name: 1 } })
    //  this.db.members = await <any>this.db.httpPost('find', { system: this.db.system, table: 'teamsalesmembers', condition: { active: true }, projection: { id: 1, firstName: 1, lastName: 1, idTeam: 1 }, sort: { firstName: 1 } })
    //}

    // Compact mode
    if (this.db.configuration.compactMode && this.deviceService.isMobile()) this.db.compact = true

    if (this.debug) console.log('Setup done')

    // If qr...
    if (this.db.qrCode) {

      if (this.debug) console.log('QR', this.db.qrCode)

      this.db.setMealOfTheDay(moment().format('YYYY-MM-DD'))

      // Check QR an set order type
      let series = this.db.qrCode.substr(0, 1).toUpperCase()
      let nr = +this.db.qrCode.substr(1)
      //console.log('Series', series, 'Nr', nr)

      let idOrderType = ''
      
      // Set table
      this.db.qrRanges.filter(qrr => qrr.series.toUpperCase() == series).forEach(qrr => {

        let expanded = this.expandRange(qrr.range)

        if (expanded.includes(nr)) {

          idOrderType = qrr.idOrderType
          this.db.cart.table = `${qrr.prefix} ${nr}`

          // Updatde cid with table info
          this.db.httpPost('updateone', { system: this.db.system, table: 'cidinfo', condition: { cid: this.db.cid }, data: { table: this.db.cart.table, tablets: moment().format('YYYY-MM-DD HH:mm:ss') }, token: this.db.token }).then((result) => {})

        }

      })

      // Table tokens
      if (this.db.configuration.useTableTokens) {

        // Disable cash payment types
        let cashPaymentTypes = []
        this.db.paymentTypes.filter(pt => pt.provider == 1).forEach(pt => {
          if (idOrderType) {
            let ot = this.db.orderTypes.find(ot => ot.id == idOrderType)
            if (ot) ot.paymentTypes.filter(otpt => otpt.idPaymentType == pt.id).forEach(otpt => {
              cashPaymentTypes.push(otpt)
            })
          }
        })
        cashPaymentTypes.forEach(cpt => { cpt.active = false })

        // Get token
        let tableToken = await <any>this.db.httpPost('findone', { system: this.db.system, table: 'tabletokens', condition: { id: this.db.ttid, validTo: { $gt: moment().format('YYYY-MM-DD HH:mm:ss' )} }, token: this.db.token })

        // If token is valid, enable cash payment types
        if (tableToken && `${tableToken.series}${tableToken.number.toString()}` == this.db.qrCode) {
          localStorage.setItem('ttid', this.db.ttid)
          cashPaymentTypes.forEach(cpt => { cpt.active = true })
        }

      }      

      if (idOrderType) {

        let ot = this.db.orderTypes.find(ot => ot.id == idOrderType)

        if (ot) {

          this.db.orderType = ot
          this.db.disableExcludedProducts()
          this.db.disableExcludedAddons()

          // Green theme on Stångs Mjärdevi orderType Vita Huset alt FOI
          if (this.db.system == 'stangs-mjardevi' && (this.db.orderType.id == 'cc794fac-d068-4ed9-9bbe-eff80874aae3' || this.db.orderType.id == '0da5bd35-8b52-467f-9599-4fe90e597f06')) {
            this.db.configuration.boxColor = '#7d945c'
            this.db.configuration.buttonSelectedColor = '#7d945c'
            this.db.configuration.buttonColor = '#7d945c'
            let cs = this.db.customStyles.find(cs => cs.nr == 1021)
            if (cs) cs.style = `font-family: TrimMono; border-radius: 0; border-width: 2px; border-color: #7d945c;`
            this.db.stylePipeHelper++
          }

          this.db.setBg(this.db.orderType.idBackground)

          this.setCategories()

          //this.db.step = 40
          this.db.navigate('/products')
          
        }

      }

    } else if (this.db.idCustomer && this.db.idOrderType) {

      //console.log('Cust', this.db.orderTypes)

      let orderType = this.db.orderTypes.find(ot => ot.id == this.db.idOrderType)
      if (!orderType) return
      this.db.orderType = orderType

      let customer: Customer = await <any>this.db.httpPost('findone', { system: this.db.system, table: 'customers', condition: { id: this.db.idCustomer }, token: this.db.token })
      if (!customer) return
      this.db.customer = customer

      this.db.cart.firstName = customer.firstName || ''
      this.db.cart.lastName = customer.lastName || ''
      this.db.cart.lastName2 = customer.lastName2 || ''
      this.db.cart.email = customer.email || ''
      this.db.cart.phone = customer.phone || ''
      if (this.db.cart.phone.length == 10) this.db.cart.phone = `+46${this.db.cart.phone.substring(1)}`
      this.db.cart.idCustomer = customer.id

      this.db.disableExcludedProducts()
      this.db.disableExcludedAddons()
  
      this.db.setBg(this.db.orderType.idBackground)
  
      // Enable categories for this order type
      this.db.categories.filter(c => !c.idParent).forEach(c => { c.active = false })
      this.db.orderType.categories.filter(otc => otc.active).forEach(otc => {
        let c = this.db.categories.find(c => c.id == otc.idCategory)
        if (c) c.active = true
      })
  
      //this.db.step = 20
      this.db.navigate('/dayandtime')

    } else if (this.db.project) {
      
      //this.db.project = await this.db.httpPost('findone', { system: this.db.system, table: 'teamsalesprojects', condition: { urlName: this.db.projectName, active: true }, token: this.db.token })
      //if (!this.db.project) return
      
      let otId = this.db.project.ftg ? this.db.project.idOrderType2 : this.db.project.idOrderType

      let ot = this.db.orderTypes.find(ot => ot.id == otId)
      if (!ot) return 

      this.db.orderType = ot

      this.db.setBg(this.db.orderType.idBackground)

      this.setCategories()

      //this.db.navigate('/products')
      this.db.step = 40
      
    } else {

      // If not QR

      if (this.debug) console.log('Show ordertypes')

      //this.db.step = 10 // Show ordertypes
      this.db.navigate('/ordertypes')

    }

    // Pappas pärlor
    //if (this.db.system == 'pappasparlor') {
    //  this.db.addressFields = ['address1', 'address2', 'postcode', 'city', 'country']
    //  this.db.imageFolder = 'thumbnails'
    //  this.db.showInfoButton = false
    //  this.db.showQuantitySelector = false
    //  this.db.imageClickAdd = false
    //  this.db.showDescription = false
    //}

    // Foreningsforsaljning
    //if (this.db.system == 'foreningsforsaljning') {
    //  this.db.addressFields = ['address1', 'address2', 'postcode', 'city']
    //  this.db.imageClickAdd = true
    //  this.db.showDescription = true
    //  this.db.topMargin = '110px'
    //  this.db.showProductnameAbove = false
    //  if (this.db.orderType.id == 'df763a1d-83af-428d-8d74-b7c3fdaf5461') this.db.addressFields.push('companyName', 'businessId')
    //}

    // Grillkol
    if (this.db.system == 'grillkol') {
      this.db.addressFields = ['address1', 'address2', 'postcode', 'city']
      this.db.imageClickAdd = false
      this.db.showDescription = true
      this.db.showProductnameAbove = true
    }

    if (this.db.system == 'foreningskryddor') {
      this.db.addressFields = ['address1', 'address2', 'postcode', 'city']
      this.db.imageClickAdd = false
      this.db.showDescription = true
      this.db.showProductnameAbove = true
    }


    // MQTT

    // Subscribe to system messages
    this.mqtt.subscribe(`flow/client/${this.db.system}`)

    // Subscribe to CID messages
    this.mqtt.subscribe(`flow/client/${this.db.system}/${this.db.cid}`)

    // Subscribe to table messages
    if (this.db.cart.table) this.mqtt.subscribe(`flow/table/${this.db.system}/${this.db.cart.table}`)

    // Subscribe to product block messages
    this.mqtt.subscribe(`flow/block/${this.db.system}`)

    // Listen to incoming messages
    this.mqttSubscription = this.mqtt.mqttGetMessage().subscribe((data) => {

      if (this.debug) console.log('Incoming mqtt message', data.topic)
      if (this.debug) console.log(data.message.payload.toString())
      
      // CID Messages
      if (data.topic == `flow/client/${this.db.system}/${this.db.cid}`) {

        let msg = JSON.parse(data.message.payload.toString())

        // Payment successful
        if (msg && msg.payed) {
          //this.db.step = 60
          //console.log('mqtt paysuccess')
          this.db.step = 60//this.db.navigate('/paysuccess')
        }

        // Payment cancelled
        if (msg && msg.err && msg.err == 'DECLINED') {
          this.db.step = 50
          //this.db.navigate('/cart')
        }

      }

      // Table messages
      if (this.db.configuration.interactiveTables && data.topic == `flow/table/${this.db.system}/${this.db.cart.table}`) {
        
        let msg = JSON.parse(data.message.payload.toString())

        //console.log('Table message', msg)

        if (msg.cid != this.db.cid) {
          let name = msg.firstName || this.lang.l(299)
          let message = this.lang.l(298).replace(/{name}/, name).replace(/{product}/, msg.productName)
          //let message = `${name} har lagt ${msg.productName} i varukorgen`
          this.snackBar.open(message, '', { duration: 3000, panelClass: 'green-snackbar' })
        }

      }

      // Block messages
      if (data.topic == `flow/block/${this.db.system}`) {

        let obj = JSON.parse(data.message.payload.toString())

        console.log('Block message', obj)

        if (obj.idProduct) {
          let product = this.db.products.find(p => p.id == obj.idProduct)
          if (product) {
            product.blockedTo = obj.blockedTo
            this.db.categoryPipeHelper++
          }
        }

        if (obj.idCategory) {
          let category = this.db.categories.find(c => c.id == obj.idCategory)
          if (category) {
            category.blockedTo = obj.blockedTo
            this.db.checkBlockedCategories()
            this.db.categoryPipeHelper++
          }
        }

      }

      // System messages
      if (data.topic == `flow/client/${this.db.system}`) {

        let obj = JSON.parse(data.message.payload.toString())

        // Client Messages
        if (obj.cmd == 'addclientmessage') this.db.clientMessages.push(obj.clientMessage)
        if (obj.cmd == 'delclientmessage') this.db.clientMessages = this.db.clientMessages.filter(cm => cm.id != obj.clientMessage.id)

      }

    })


  }

  setCategoryLevel(c, level) {

    c.level = level

    this.db.categories.filter(_c => _c.idParent == c.id).forEach(_c => { 
      if (this.avoidLoopOfDeath++ < 500) this.setCategoryLevel(_c, level + 1) 
    })

  }

  setCategories() {

          // Categories
          this.db.categories.forEach(c => {
            c.active = false
            if (!c.idParent) c.idParent = ''
          })
          this.db.orderType.categories.filter(otc => otc.active).forEach((otc, index) => {
            
            let c = this.db.categories.find(c => c.id == otc.idCategory)
            c['idPriceList'] = otc.idPriceList || '' // Hack for AW pricelist etc
            
            let cal = this.db.calendars.find(cal => cal.id == otc.idCalendar)
            
            if (cal) {

              //let timepSpans = this.db.parseCalendar(cal, this.db.workday)
              let timepSpans = this.db.getTimespans(cal, this.db.workday)
              //console.log('=>', c.name, cal.name, timepSpans)

              timepSpans.forEach(timeSpan => {
                //console.log(c.name, cal.name, timeSpan.startDate, timeSpan.endDate)
                if (moment().isBetween(moment(timeSpan.startDate), moment(timeSpan.endDate))) {
                  c.active = true
                }
              })

            }

            if (c) {
              if (this.db.bypassCalendars) c.active = true // Use to bypass calendar
              //c.sortorder = index
            }

          })

          this.db.categories = this.db.categories.sort((a, b) => { if (a.sortorder < b.sortorder) return -1; if (a.sortorder > b.sortorder) return 1; return 0; })

  }

//  createCategoryTree() {
//
//    this.db.categoryTree = []
//
//    this.db.categories.filter(c => !c.idParent).forEach(c => {
//
//      let ct = { level: 0, idCategory: c.id, topCategory: c.id, children: [] }
//      this.addCategoryChildren(ct.children, c.id, c.id, 0)
//      this.db.categoryTree.push(ct)
//
//    })
//
//    console.log('>>>>>>>>>>', this.db.categoryTree)
//
//  }
//
//  addCategoryChildren(tree, idCategory, topCategory, level) {
//    this.db.categories.filter(c => c.idParent == idCategory).forEach(c => {
//
//      let ct = { level: level + 1, idCategory: c.id, topCategory: topCategory, children: [] }
//      this.addCategoryChildren(ct.children, c.id, topCategory, ct.level)
//      tree.push(ct)
//
//    })
//  }

  expandRange(range) {

    let expanded = []

    let chunks = range.split(',')

    chunks.forEach(chunk => {
      
      let r = chunk.trim().split('-')
      let r1 = +r[0]
      let r2 = +r[1]
      
      if (!r2) expanded.push(r1)
      if (r2) for (let n = r1; n <= r2; n++) expanded.push(n)

    })

    return expanded

  }

  async setupProject(system, urlName, memberName) {

    console.log('setupProject', urlName, memberName)

    let urlNameParts = urlName.split('-')

    let project = await <any>this.db.httpPost('findone', { system: system, table: 'teamsalesprojects', condition: { urlName: urlNameParts[0].toUpperCase(), active: true }, token: this.db.token })
    if (!project) return console.log('Noo')

    if (urlNameParts[1] == project.id.substring(0,4).toUpperCase()) project.ftg = true

    project.description1 = project.description1.replace(/{slutdatum}/g, project.projectEnd || '??')
    this.db.project = project

    this.db.cart.idProject = project.id

    this.db.teams = await <any>this.db.httpPost('find', { system: system, table: 'teamsalesteams', condition: { idProject: this.db.project.id, active: true }, projection: { _id: 0, id: 1, name: 1 }, sort: { name: 1 } })
    this.db.members = await <any>this.db.httpPost('find', { system: system, table: 'teamsalesmembers', condition: { idProject: this.db.project.id, active: true }, projection: { _id: 0, id: 1, firstName: 1, lastName: 1, idTeam: 1, code: 1 }, sort: { firstName: 1 } })
    this.db.teamsalesSettings = await <any>this.db.httpPost('findone', { system: this.db.system, table: 'teamsalessettings', condition: {  }, projection: { _id: 0, id: 1, idShippingProduct: 1 }, token: this.db.token })
    //console.log(this.db.teamsalesSettings)

    for (let member of this.db.members) if (member.idTeam === null) member.idTeam = ''

    if (memberName) {
      let member = this.db.members.find(m => m.code == memberName)
      if (member) {
        this.db.cart.idMember = member.id
        if (member.idTeam) this.db.cart.idTeam = member.idTeam
      }
    }
    
    //console.log('teams', this.db.teams)
    //console.log('members', this.db.members)

  }
  



}
