<div *ngIf="db.step == 40"><app-step40></app-step40></div>
<div *ngIf="db.step == 50"><app-step50></app-step50></div>
<div *ngIf="db.step == 60"><app-step60></app-step60></div>
<div *ngIf="db.step == 100"><app-step100></app-step100></div>
<div *ngIf="db.step == 110"><app-step110></app-step110></div>
<div *ngIf="db.step == 200"><app-step200></app-step200></div>
<div *ngIf="db.step == 300"><app-step300></app-step300></div>
<div *ngIf="db.step == 400"><app-step400></app-step400></div>
<div *ngIf="db.step == 500"><app-step500></app-step500></div>
<div *ngIf="db.step == 600"><app-step600></app-step600></div>
