import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { DbService, LangService } from '../../services'
import { faTimes, faCircleNotch, faSpinner } from '@fortawesome/pro-light-svg-icons'
import { v4 as uuid } from 'uuid'
import { CartProduct } from '../../interfaces'
import { MatSnackBar } from '@angular/material/snack-bar'
import * as moment from 'moment';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {FormGroup, FormControl, Validators} from '@angular/forms';

@Component({
  selector: 'app-step50',
  templateUrl: './step50.component.html',
  styleUrls: ['./step50.component.scss']
})
export class Step50Component implements OnInit {

  @ViewChild("shareRef") shareRef: TemplateRef<any>;
  @ViewChild("giftcardRef") giftcardRef: MatDialogRef<any>;

  faTimes = faTimes; faCircleNotch = faCircleNotch; faSpinner = faSpinner

  disableForm = false
  //termsAccepted = false
  spainInvoice = false

  showTip = false
  showDC = false // Show Discount Code
  discountCode = ''

  pipeHelper = 0

  giftcardCode = ''

  userForm

  preferredCountries = []

  cartHasGiftCard = false

  constructor(public db: DbService, public lang: LangService, public snackBar: MatSnackBar, public dialog: MatDialog) {

    this.preferredCountries.push(this.lang.defaultLanguageCode)

    if (this.db.configuration.phonePreferredCountries) {
      let pfcs = this.db.configuration.phonePreferredCountries.split(',')
      pfcs.forEach(pfc => { this.preferredCountries.push(pfc.trim()) })
    }
    //console.log(this.db.cart)

    this.cartHasGiftCard = this.db.cart.products.filter(cp => cp.type == 1).length ? true : false

    if (!this.db.cart.firstName) this.db.cart.firstName = localStorage.getItem('firstName') || ''
    if (!this.db.cart.lastName) this.db.cart.lastName = localStorage.getItem('lastName') || ''
    if (!this.db.cart.phone) this.db.cart.phone = localStorage.getItem('phone') || ''
    if (!this.db.cart.email) this.db.cart.email = localStorage.getItem('email') || ''
    
    this.userForm = new FormGroup({
      firstName: new FormControl({ value: this.db.cart.firstName, disabled: this.disableForm }, [Validators.required, Validators.minLength(2)]),
      lastName: new FormControl({ value: this.db.cart.lastName, disabled: this.disableForm }, [Validators.required, Validators.minLength(2)]),
      phone: new FormControl({ value: this.db.cart.phone, disabled: this.disableForm }, []),
      email: new FormControl({ value: this.db.cart.email, disabled: this.disableForm }, [Validators.required]),
      address1: new FormControl({ value: '', disabled: this.disableForm }, []),
      address2: new FormControl({ value: '', disabled: this.disableForm }, []),
      postCode: new FormControl({ value: '', disabled: this.disableForm }, []),
      city: new FormControl({ value: '', disabled: this.disableForm }, []),
      country: new FormControl({ value: '', disabled: this.disableForm }, []),
      info: new FormControl({ value: '', disabled: this.disableForm }, []),
      teamDelivery: new FormControl({ value: true, disabled: this.disableForm }, []),
      directDelivery: new FormControl({ value: false, disabled: this.disableForm }, []),
      termsAccepted: new FormControl({ value: false, disabled: this.disableForm }, [Validators.requiredTrue]),
      spainInvoice: new FormControl({ value: false, disabled: this.disableForm }, []),
      razonSocial: new FormControl({ value: this.db.cart.razonSocial, disabled: this.disableForm }, []),
      nif: new FormControl({ value: this.db.cart.nif, disabled: this.disableForm }, []),
      domicilioSocial: new FormControl({ value: this.db.cart.domicilioSocial, disabled: this.disableForm }, []),
      companyName: new FormControl({ value: this.db.cart.companyName, disabled: this.disableForm }, []),
      businessId: new FormControl({ value: this.db.cart.businessId, disabled: this.disableForm }, []),
    })
  
    if (this.db.teamsalesSettings.idShippingProduct) this.db.directDeliveryProduct = this.db.products.find(p => p.id == this.db.teamsalesSettings.idShippingProduct)
    //console.log(88, this.db.directDeliveryProduct)
    
    if (this.db.cart.products.filter(p => p.idProduct == this.db.directDeliveryProduct?.id).length) {
      this.userForm.teamDelivery = false
      this.userForm.directDelivery = true
    } else {
      this.userForm.teamDelivery = true
      this.userForm.directDelivery = false
    }

  }
  
  ngOnInit(): void {
    this.userForm.controls['phone'].markAsTouched()    
  }

  close() {
    this.db.step = 40
    //this.db.navigate('/products')
  }

  delItem(cp) {

    this.db.cart.products = this.db.cart.products.filter(_cp => _cp.idParent != cp.id)
    this.db.cart.products = this.db.cart.products.filter(_cp => _cp.id != cp.id)
    this.db.productPipeHelper++
    if (!this.db.cart.products.length) this.db.step = 40
    //if (!this.db.cart.products.length) this.db.navigate('/products')

    // Return if to few dishes
    //if (this.db.orderType.type < 2 && this.db.orderType.minimumDishes && this.db.dishCount() < this.db.orderType.minimumDishes) this.db.navigate('/products')
    if (this.db.orderType.minimumDishes && this.db.dishCount() < this.db.orderType.minimumDishes) this.db.step = 40 //this.db.navigate('/products')

    // Return if to low order amount
    this.db.calculateCartTotals() // Just to make sure
    if (this.db.orderType.type < 2 && this.db.orderType.minimumAmount && this.db.cart.totalAmount < this.db.orderType.minimumAmount) this.db.step = 40 //this.db.navigate('/products')

  }

//  delItemXXX(_cp) {
////console.log(_cp)
//    let lastOccurence = -1; let addonCount = 0
//    
//    this.db.cartProducts.forEach((cp, index) => {
//      
//      if (cp.idProduct == _cp.idParent) {
//        lastOccurence = index
//      }
//
//    })
//
//    if (lastOccurence >= -1) {
//      this.db.cartProducts = this.db.cartProducts.filter(cp => cp.idParent != this.db.cartProducts[lastOccurence].id)
//      this.db.cartProducts.splice(lastOccurence, addonCount + 1)
//      this.db.productPipeHelper++
//    }
//
//  }

  tip(n, tipType) {

    // n = tip value
    // tipType 0 = percent
    // tipType 1 = amount

    // Abort if no tip product
    let tipProduct = this.db.products.find(p => p.isTip)
    if (!tipProduct) return

    // Remove previous tip if it exists
    this.db.cart.products = this.db.cart.products.filter(c => !c.isTip)

    let tipAmount = 0
    
    // If percentage
    if (tipType == 0) {

      // Calculate Tip amount
      let a = this.db.cartTotal() * n / 100

      // Round amount according to configuration.roundingDigits
      let factor = 1 ** this.db.configuration.roundingDigits
      tipAmount = Math.round(a / factor) * factor

    }

    // If amount
    if (tipType == 1) tipAmount = n

    let _cp: CartProduct = {
      id: uuid(),
      cid: this.db.cid,
      idProduct: tipProduct.id,
      type: 2,
      name: tipProduct.name,
      idProductGroup: tipProduct.idProductGroup,
      bongText: '',
      otherLanguages: tipProduct.otherLanguages,
      quantity: 1,
      unit: tipProduct.unit || '',
      idParent: '',
      price: tipAmount,
      priceExcl: tipAmount,
      ordPrice: 0,
      vatPercent: 0,
      totalAmount: tipAmount,
      totalAmountExcl: tipAmount,
      totalVatAmount: 0,
      discountAmount: 0,
      discountAmountExcl: 0,
      discountVatAmount: 0,
      discountPercent: 0,
      isTip: true,
      signature: '',
      isSotd: false,
      isStock: false,
      isDish: false,
      idPrinters: [],
      remoteId: tipProduct.remoteId || '',
      remoteQuantity: tipProduct.remoteQuantity || 1,
      mealType: null
    }

    this.db.cart.products.push(_cp)
    this.db.productPipeHelper++

  }

  setPayMethod(pt) {
    let paymentType = this.db.paymentTypes.find(_pt => _pt.id == pt.idPaymentType)
    this.db.cart.paymentMethod = paymentType ? paymentType.provider : 0
    this.db.cart.idPaymentType = paymentType ? paymentType.id : ''
    this.db.cart.paymentType = paymentType
  }

  async pay(form) {

    //console.log('pay', form)
    //console.log('userForm', this.userForm)
    //return

    if (this.userForm.value.firstName) localStorage.setItem('firstName', this.userForm.value.firstName)
    if (this.userForm.value.lastName) localStorage.setItem('lastName', this.userForm.value.lastName)
    if (this.userForm.value.phone) localStorage.setItem('phone', this.userForm.value.phone)
    if (this.userForm.value.email) localStorage.setItem('email', this.userForm.value.email)

    // Check for products with no price
    let panic = false
    this.db.cart.products.filter(p => !p.idParent).forEach(cp => {      
      let addonTotalPrice = this.db.cart.products.filter(acp => acp.idParent == cp.id).reduce((acc, acp) => acc += acp.totalAmount, 0)
      //console.log(cp.name, cp.totalAmount, addonTotalPrice)
      if (cp.totalAmount + addonTotalPrice < 0.1) panic = true
    })

    // Do not allow zero amount
    if (this.db.cart.totalAmount <= 0) return

    if (panic && this.db.system != 'stangs-mjardevi') {
      //console.log('panic', this.db.cart.products)
      await this.db.httpPost('insertone', { system: this.db.system, table: 'zeroprice', data: { id: uuid(), cid: this.db.cid, createdts: moment().format('YYYY-MM-DD HH:mm:ss'), cart: this.db.cart }, token: this.db.token })
      return
    }

    // Check that terms are accepted
    if (!this.userForm.value.termsAccepted) {
      this.snackBar.open(this.lang.l(228), '', { duration: 2000, panelClass: 'green-snackbar' })
      return
    }

    // Check Team Sales
    //if (this.db.configuration.teamSales && (!this.db.cart.idTeam || !this.db.cart.idMember)) {
    //  this.snackBar.open('Du måste välja både lag och lagmedlem!', '', { duration: 2000, panelClass: 'green-snackbar' })
    //  return
    //}
    if (this.db.configuration.teamSales) {

      if (this.db.teams.length) {
        if (!this.db.cart.idTeam || !this.db.cart.idMember) {
          this.snackBar.open('Du måste välja både lag/klass och säljare!', '', { duration: 2000, panelClass: 'green-snackbar' })
          return  
        }
      } else {
        if (!this.db.cart.idMember) {
          this.snackBar.open('Du måste välja säljare!', '', { duration: 2000, panelClass: 'green-snackbar' })
          return
        }
      }
      
    }

    // If giftcard ask for code
    if (this.db.cart.paymentMethod == 13) {
      this.giftcardCode = ''
      let ref = this.dialog.open(<any>this.giftcardRef, { position: { top: '5%' }, maxHeight: '90%' })
      await ref.afterClosed().toPromise()
      this.db.cart.giftcardCode = this.giftcardCode.toUpperCase()
      this.db.cart.giftcardSystem = this.db.configuration.giftCardSystem || this.db.system
    }    

    this.disableForm = true

    this.db.cart.id = uuid() // Make sure cart has new id every time payment is initiated
    this.db.cart.idCart = this.db.cart.id
    this.db.cart.cid = this.db.cid

    let now = moment()

    this.db.cart.ts = now.format('YYYY-MM-DD HH:mm:ss')

    // If QR set immediate delivery
    if (this.db.orderType.type == 2) {

      this.db.cart.deliveryDate = now.format('YYYY-MM-DD')
      this.db.cart.deliveryTime = now.format('HH:mm')
      this.db.cart.deliveryMinute = now.hours() * 60 + now.minutes()

      if (now.hour() < 4) {
        this.db.cart.deliveryDate = now.subtract(1, 'day').format('YYYY-MM-DD')
        this.db.cart.deliveryMinute += 1440
      }
      
    }

    this.db.cart.firstName = form.firstName.trim()
    this.db.cart.lastName = form.lastName.trim()
    this.db.cart.email = form.email.trim()
    this.db.cart.phone = form.phone.trim()
    this.db.cart.info = form.info || ''

    this.db.cart.companyName = form.companyName || ''
    this.db.cart.businessId = form.businessId || ''

    this.db.cart.address1 = form.address1 || ''
    this.db.cart.address2 = form.address2 || ''
    this.db.cart.postCode = form.postCode || ''
    this.db.cart.city = form.city || ''
    this.db.cart.country = form.country || ''

    this.db.cart.domicilioSocial = form.domicilioSocial || ''
    this.db.cart.nif = form.nif || ''
    this.db.cart.razonSocial = form.razonSocial || ''

    this.db.cart.orderType.id = this.db.orderType.id
    this.db.cart.orderType.idConfirmation = this.db.orderType.idConfirmation
    this.db.cart.orderType.idPriceList = this.db.orderType.idPriceList
    this.db.cart.orderType.type = this.db.orderType.type
    this.db.cart.orderType.name = this.db.orderType.name
    this.db.cart.orderType.idPushoverGroup = this.db.orderType.idPushoverGroup || ''
    this.db.cart.orderType.printerOverrides = this.db.orderType.printerOverrides || []

    this.db.cart.activeLanguageCode = this.lang.activeLanguageCode
    this.db.cart.activeLocale = this.lang.activeLocale

    this.db.cart.dishCount = this.db.dishCount()

    if (this.db.deliveryAddress) this.db.cart['deliveryAddress'] = this.db.deliveryAddress

    // Print date
    let printTimeDaysAhead = this.db.orderType.printTimeDaysAhead || 0
    this.db.cart.printDate = moment(this.db.cart.deliveryDate).subtract(printTimeDaysAhead, 'days').format('YYYY-MM-DD')

    // Print minute
    //let defaultPrintTimeFloating = this.db.orderType.type == 2 ? 0 : 60
    let floating = this.db.orderType.printTimeFloating || 0
    if (!floating && this.db.orderType.type < 2) floating = 60
    let printTimeFloating = floating
    let printTimeFixed = this.db.orderType.printTimeFixed || 0

    this.db.cart.printMinute = this.db.cart.deliveryMinute - printTimeFloating
    if (printTimeFixed) this.db.cart.printMinute = printTimeFixed

    if (this.db.system == 'fika') {
      this.db.cart.printDate = moment().format('YYYY-MM-DD')
      this.db.cart.printMinute = moment().hour() * 60 + moment().minute()
    }

    //let cidInfo = {
    //  id: uuid(),
    //  cid: this.db.cid,
    //  firstName: form.firstName.trim(),
    //  lastName: form.lastName.trim(),
    //  email: form.email.trim(),
    //  phone: form.phone.trim(),
    //  updatedts: moment().format('YYYY-MM-DD HH:mm:ss')
    //}

    //let result1 = await this.db.httpPost('updateone', { system: 'global', table: 'cidinfo', condition: { cid: cidInfo.cid }, data: cidInfo, upsert: true, token: this.db.token })

    await this.db.httpPost('updateone', { system: 'global', table: 'cidinfo', condition: { cid: this.db.cid }, data: { 
      firstName: form.firstName.trim(),
      lastName: form.lastName.trim(),
      email: form.email.trim(),
      phone: form.phone.trim(),
    }, token: this.db.token })

    this.db.calculateCartTotals()
    
    // Wait for paymentMethod to be set by other function
    let waitForPaymentMethod = true
    while (waitForPaymentMethod) {
      await this.sleep(100)
      if (typeof this.db.cart.paymentMethod !== 'undefined') waitForPaymentMethod = false
    }

    //console.log(this.db.cart.paymentMethod)

    let result2 = await this.db.httpPost('updateone', { system: this.db.system, table: 'carts', condition: { id: this.db.cart.id }, data: this.db.cart, upsert: true, token: this.db.token })
    
    let result3 = await <any>this.db.httpPost('pay', { system: this.db.system, idCart: this.db.cart.id, token: this.db.token })
    //console.log('r3', result3)
    
    //if (result3.err) 

    // Send GTM Purchase
    this.db.sendGtmPurchase()

    if (this.db.cart.paymentMethod == 1) this.db.step = 60
    //if (this.db.cart.paymentMethod == 1) this.db.navigate('/paysuccess')

    // Swish
    if (this.db.cart.paymentMethod == 2) {
      if (result3.id) this.db.swishId = result3.id
      this.db.step = 200 
      //this.db.navigate('/payswish')
    }

    // Adyen
    if (this.db.cart.paymentMethod == 3) {
      this.db.adyenRequest = result3
      this.db.step = 100
      //this.db.navigate('/payadyen')
    }

    // Nets Easy
    if (this.db.cart.paymentMethod == 16) {
      this.db.adyenRequest = result3
      this.db.navigate('/paynetseasy')
    }

    // Bambora
    if (this.db.cart.paymentMethod == 4) {
      this.db.bamboraSession = result3
      this.db.step = 300
      //this.db.navigate('/paybambora')
    }

    // Bizum
    if (this.db.cart.paymentMethod == 5 || this.db.cart.paymentMethod == 6) {
      this.db.bizumSession = result3
      this.db.navigate('/paybizum')
    }

    // Paycomet
    if (this.db.cart.paymentMethod == 7 || this.db.cart.paymentMethod == 8) {
      this.db.paycometSession = result3
      this.db.navigate('/paycomet')
    }

    // Stripe
    if (this.db.cart.paymentMethod == 11) {
      if (result3 && result3.url) {
        this.db.stripeUrl = result3.url
        this.db.navigate('/paystripe')
      }
    }   

    if (this.db.cart.paymentMethod == 13) {
      if (result3) {
        this.db.step = 60//this.db.navigate('/paysuccess')
      } else {
        this.snackBar.open(this.lang.l(398), '', { duration: 3000, panelClass: 'green-snackbar' })
        this.disableForm = false
      }
    }
    
    //console.log(this.db.cart)

  }

  sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  shareCart = () => {
    this.dialog.open(this.shareRef, { position: { top: '5%' }, maxHeight: '90%' })
  }
  
  teamChange(e) {
    this.db.cart.idMember = ''
    this.pipeHelper++
  }

  memberChange(e) {}  

  async addDiscountCode(v) {

    // Remove existing discount code
    this.db.cart.discountCode = null

    // Get discount code
    let result = await <any>this.db.httpPost('discountcode', { system: this.db.system, code: v.code, token: this.db.token })

    //console.log(result)

    // Validate
    if (result.result) {

      this.db.cart.discountCode = {
        code: v.code,
        amount: result.amount || 0,
        percentage: result.percentage || 0,
        idDiscountCode: result.idDiscountCode
      }

      this.showDC = false
      
    } else {

      this.snackBar.open(this.lang.l(384), '', { duration: 3000, panelClass: 'green-snackbar' })

    }

    // Update cart totals
    this.db.calculateCartTotals()
    this.db.productPipeHelper++

  }

  teamDeliveryChange(e) {

    //console.log('teamDeliveryChange', e)

    if (e.checked) {

      this.userForm.controls['directDelivery'].setValue(false) //this.userForm.directDelivery = false
      this.db.cart.products = this.db.cart.products.filter(cp => cp.idProduct != this.db.directDeliveryProduct.id)
      this.db.calculateCartTotals()
      this.db.productPipeHelper++
    } else {
      this.userForm.controls['directDelivery'].setValue(true) //this.userForm.directDelivery = true
      this.directDeliveryChange({ checked: true })
    }

  }

  directDeliveryChange(e) {

    //console.log('directDeliveryChange', e)

    this.db.cart.products = this.db.cart.products.filter(cp => cp.idProduct != this.db.directDeliveryProduct.id)

    if (e.checked) {

      this.userForm.controls['teamDelivery'].setValue(false)

      let cp: CartProduct = {
        id: uuid(),
        cid: this.db.cid,
        type: this.db.directDeliveryProduct.type,
        idProduct: this.db.directDeliveryProduct.id,
        idProductGroup: this.db.directDeliveryProduct.idProductGroup,
        name: this.db.directDeliveryProduct.name,
        bongText: this.db.directDeliveryProduct.bongText,
        otherLanguages: this.db.directDeliveryProduct.otherLanguages,
        quantity: 1,
        unit: this.db.directDeliveryProduct.unit || '',
        idParent: '',
        price: this.db.getPrice(this.db.directDeliveryProduct.id),
        priceExcl: 0,
        ordPrice: this.db.getPrice(this.db.directDeliveryProduct.id, true),
        vatPercent: 0,
        totalAmount: 0,
        totalAmountExcl: 0,
        totalVatAmount: 0,
        discountAmount: 0,
        discountAmountExcl: 0,
        discountVatAmount: 0,
        discountPercent: 0,
        isTip: false,
        signature: '',
        isSotd: this.db.directDeliveryProduct.isSotd || false,
        isStock: this.db.directDeliveryProduct.isStock || false,
        isDish: this.db.directDeliveryProduct.isDish || false,
        idPrinters: this.db.directDeliveryProduct.idPrinters || [],
        remoteId: this.db.directDeliveryProduct.remoteId || '',
        remoteQuantity: this.db.directDeliveryProduct.remoteQuantity || 1,
        mealType: null
      }

      this.db.cart.products.push(cp)

    } else {
      this.userForm.controls['teamDelivery'].setValue(true) //this.userForm.teamDelivery = true
    }

    this.db.calculateCartTotals()

    this.db.productPipeHelper++

  }

}
