<div class="outer">

    <div class="logo">
        <a [href]="logoUrl"><img class="logoimg" [src]="logoImageUrl" width="75%"></a>
    </div>

    <div *ngIf="step == 2" class="section">

        <div>Kan inte hitta förening eller säljare!</div>

    </div>

    <div *ngIf="step >= 3" class="section">

        <div class="vpad">Ordersammanställning</div>
        <div class="vpad">{{this.member.firstName}} {{this.member.lastName}}</div>
        <div class="vpad">{{this.project.name}}</div>

    </div>

    <div *ngIf="step == 4" class="section">
        Inga ordrar ännu...
    </div>

    <div *ngIf="step == 5" class="section">

        <div *ngFor="let order of orders" class="ordersection">

            <table style="width: 100%">
                <tr>
                    <td>Ordernummer</td>
                    <td style="width:100%">{{order.orderNumber}}</td>
                </tr>
                <tr>
                    <td>Datum/tid</td>
                    <td style="width:100%">{{order.ts}}</td>
                </tr>
                <tr>
                    <td>Namn</td>
                    <td>{{order.firstName}} {{order.lastName}}</td>
                </tr>
                <tr>
                    <td>Adress</td>
                    <td>{{order.address1}}</td>
                </tr>
                <tr>
                    <td>Postadress</td>
                    <td>{{order.postCode}} {{order.city}}</td>
                </tr>
                <tr>
                    <td>Telefon</td>
                    <td>{{order.phone}}</td>
                </tr>
                <tr>
                    <td>E-post</td>
                    <td>{{order.email}}</td>
                </tr>
                <tr>
                    <td style="padding: 0; width: 100%;" colspan="2">
                        <table style="width: 100%;">
                            <tr>
                                <td class="tdb">Artikelnr</td>
                                <td class="tdb" style="width: 100%">Produkt</td>
                                <td class="tdb tdr">Antal</td>
                                <td class="tdb tdr">Belopp</td>
                            </tr>
                            <tr *ngFor="let product of order.products">
                                <td>{{product.remoteId}}</td>
                                <td>{{product.name}}</td>
                                <td class="tdr">{{product.quantity}}</td>
                                <td class="tdr">{{product.totalAmount}} kr</td>
                            </tr>
                            <tr>
                                <td class="tdb" colspan="3">Summa</td>
                                <td class="tdr tdb">{{order.totalAmount}} kr</td>
                            </tr>
                        </table>
                    </td>
                </tr>

            </table>

        </div>

        <!--<pre>{{orders|json}}</pre>-->

    </div>
    
</div>