import { Component, OnInit } from '@angular/core';
import { DbService } from '../../services'
import { ActivatedRoute } from '@angular/router'
import { Subscription,  } from 'rxjs';
import { TeamSalesMember, TeamSalesProject, TeamSalesTeam, TeamSalesSettings } from '../../interfaces'
import { Order } from '../../interfaces'
import { v4 as uuid } from 'uuid'
import { MatSnackBar } from '@angular/material/snack-bar'
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-member-register',
  templateUrl: './member-register.component.html',
  styleUrls: ['./member-register.component.scss']
})
export class MemberRegisterComponent implements OnInit {

  m1Url = 'https://m1.floworder.se/'

  step = 1

  member: TeamSalesMember = {
    id: '',
    idTeam: '',
    idProject: '',
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    info: '',
    parent1Name: '',
    parent1Email: '',
    parent2Name: '',
    parent2Email: '',
    code: '',
    active: true,
    sortorder: 10
  }

  settings: TeamSalesSettings
  project: TeamSalesProject
  teams: TeamSalesTeam[] = []
  selectedTeamId = ''
  selectedTeam: TeamSalesTeam

  routeSubscription: Subscription

  logoUrl = ''
  logoImageUrl = ''

  constructor(private db: DbService, private route: ActivatedRoute, public snackBar: MatSnackBar, private http: HttpClient) {

    this.db.token = uuid()

    if (window.location.hostname.includes('grillkol.se')) {
      this.db.system = 'grillkol'
      this.logoUrl = 'https://grillkol.se'
      this.logoImageUrl = 'https://media.floworder.se/grillkol/images/66307323-c559-410f-a669-24e455ac17f1.jpg'
    }

    if (window.location.hostname.includes('foreningskryddor.se')) {
      this.db.system = 'foreningskryddor'
      this.logoUrl = 'https://foreningskryddor.se'
      this.logoImageUrl = 'https://media.floworder.se/foreningskryddor/images/ef924b7d-fe5b-4615-b6fd-87a793f1b375.jpg'
    }

    if (window.location.hostname.includes('localhost')) {
      this.db.system = 'foreningskryddor'
      this.logoUrl = 'https://foreningskryddor.se'
      this.logoImageUrl = 'https://media.floworder.se/foreningskryddor/images/ef924b7d-fe5b-4615-b6fd-87a793f1b375.jpg'
    }    

    this.routeSubscription = this.route.params.subscribe(params => {
      console.log('params', params)
      if (params.idProject) this.setup(params.idProject)
    
    })    

  }

  ngOnInit(): void {
  }

  async setup(idTeamSalesProject) {

    this.settings = await <any>this.db.httpPost('findone', { system: this.db.system, table: 'teamsalessettings', condition: {  }, projection: { _id: 0, id: 1, idMemberMail: 1, mailSenderAddress: 1 }, token: this.db.token })
    if (!this.settings) return

    this.project = await <any>this.db.httpPost('findone', { system: this.db.system, table: 'teamsalesprojects', condition: { urlName: idTeamSalesProject, active: true }, projection: { _id: 0, id: 1, name: 1, urlName: 1 }, token: this.db.token })
    if (this.project) {
      this.member.idProject = this.project.id
      this.step = 1
    }

    this.teams = await <any>this.db.httpPost('find', { system: this.db.system, table: 'teamsalesteams', condition: { idProject: this.project.id }, projection: { _id: 0, id: 1, name: 1 }, sort: { name: 1 }, token: this.db.token })
    if (this.teams.length) {
      this.selectedTeam = this.teams[0]
      this.selectedTeamId = this.selectedTeam.id
    }
    //console.log('Teams', this.teams)

  }  

  teamChange(e) {
    //console.log('teamChange')
  }

  async onSubmit(e) {

    //console.log('onSubmit', e)
    //console.log('member', this.member)

    if (this.teams.length && !this.member.idTeam) return this.snackBar.open('Du måste välja lag/klass', '', { duration: 2000, panelClass: 'green-snackbar' })

    if (this.member.firstName.length < 2 || this.member.lastName.length < 2) return this.snackBar.open('Fealktig inmatning', '', { duration: 2000, panelClass: 'green-snackbar' })

    if (!this.validateEmail(this.member.email)) return this.snackBar.open('Felaktig mailadress', '', { duration: 2000, panelClass: 'green-snackbar' })
    
    let existingMember = await <any>this.db.httpPost('findone', { system: this.db.system, table: 'teamsalesmembers', condition: { idProject: this.project.id, email: this.member.email }, token: this.db.token })
    if (existingMember) return this.snackBar.open('Mailadressen är redan registrerad!', '', { duration: 2000, panelClass: 'green-snackbar' })

    this.member.id = uuid()
    this.member.code = this.createCode(5)

    await this.db.httpPost('insertone', { system: this.db.system, table: 'teamsalesmembers', data: this.member, token: this.db.token })

    let memberMailResult = await <any>this.http.post(`${this.m1Url}foreningskryddor-memberMail`, { system: this.db.system, idProject: this.project.id, idMembers: [this.member.id] }).toPromise()
    console.log('memberMailResult', memberMailResult)

    this.step = 2

  }

  createCode(codeLength: number): string {

    let s = ''

    let codeChars = '23456789ABCDEFGHJKLMNPQRSTUVWXYZ'

    for (var n = 1; n <= codeLength; n++) {
      s += codeChars[this.getRndInteger(0, codeChars.length - 1)]
    }

    return s
    
  }  

  getRndInteger(min: number, max: number) {
    return Math.floor(Math.random() * (max - min) ) + min;
  }  

  validateEmail(adr) {
    console.log('>>', adr)
    var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  
    if (adr.match(validRegex)) return true

    return false
  
  }  

}

