import { Pipe, PipeTransform } from '@angular/core';
import { PaymentType } from '../interfaces'
import { DbService } from '../services'

@Pipe({
  name: 'payButtons'
})
export class PayButtonsPipe implements PipeTransform {

  constructor(private db: DbService) {}

  transform(paymentTypes: PaymentType[], pipeHelper: number): PaymentType[] {

    return paymentTypes

//    if (this.db.system != 'stangs-mjardevi') return paymentTypes
//
//    if (this.db.cartTotal() > 0) return paymentTypes.filter(pt => pt.id == 'c74ee0d5-8fd2-4a6b-8f88-176dd66333ea')
//
//    return paymentTypes.filter(pt => pt.id == '3fd9beac-c76f-4eb6-beb5-1a38e7184b88')

  }

}
