import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainComponent } from './components/main/main.component';
import { AuthGuard } from './auth.guard';
import { Step10Component } from './components/step10/step10.component';
import { Step20Component } from './components/step20/step20.component';
import { Step30Component } from './components/step30/step30.component';
import { Step40Component } from './components/step40/step40.component';
import { Step50Component } from './components/step50/step50.component';
import { Step60Component } from './components/step60/step60.component';
import { Step100Component } from './components/step100/step100.component';
import { Step110Component } from './components/step110/step110.component';
import { Step200Component } from './components/step200/step200.component';
import { Step300Component } from './components/step300/step300.component';
import { Step400Component } from './components/step400/step400.component';
import { Step500Component } from './components/step500/step500.component';
import { Step600Component } from './components/step600/step600.component';
import { PayResultComponent } from './components/pay-result/pay-result.component';
import { KvitteraComponent } from './components/kvittera/kvittera.component';
import { CheckComponent } from './components/check/check.component';
import { UpdateAddressComponent } from './components/update-address/update-address.component';
import { OrdersComponent } from './components/orders/orders.component';
import { MemberRegisterComponent } from './components/member-register/member-register.component';

const routes: Routes = [
  { path: 'ordrar/:idProject/:idMember', component: OrdersComponent },  
  { path: 'registrera/:idProject', component: MemberRegisterComponent },
  { path: ':idProject/:idMember', component: MainComponent },
  { path: ':idProject', component: MainComponent },
  //{ path: '', component: MainComponent },
  //{ path: ':system', component: MainComponent },
  //{ path: ':system/ordertypes', canActivate: [AuthGuard], component: Step10Component },
  //{ path: ':system/dayandtime', canActivate: [AuthGuard], component: Step20Component },
  //{ path: ':system/address', canActivate: [AuthGuard], component: Step30Component },
  //{ path: ':system/products', canActivate: [AuthGuard], component: Step40Component },
  //{ path: ':system/cart', canActivate: [AuthGuard], component: Step50Component },
  //{ path: ':system/payadyen', canActivate: [AuthGuard], component: Step100Component },
  //{ path: ':system/paynetseasy', canActivate: [AuthGuard], component: Step110Component },
  //{ path: ':system/payswish', canActivate: [AuthGuard], component: Step200Component },
  //{ path: ':system/paybambora', canActivate: [AuthGuard], component: Step300Component },
  //{ path: ':system/paybizum', canActivate: [AuthGuard], component: Step400Component },
  //{ path: ':system/paycomet', canActivate: [AuthGuard], component: Step500Component },
  //{ path: ':system/paystripe', canActivate: [AuthGuard], component: Step600Component },
  //{ path: ':system/paysuccess', canActivate: [AuthGuard], component: Step60Component },
  //{ path: ':system/delivery', loadChildren: () => import('./delivery/delivery.module').then(m => m.DeliveryModule) },
  //{ path: ':system/qr/:qrCode', component: MainComponent },
  //{ path: ':system/shop', component: MainComponent },
  //{ path: ':system/shop/:projectName', component: MainComponent },
  //{ path: ':system/shop/:projectName/:memberName', component: MainComponent },
  //{ path: ':system/c/:idCustomer', component: MainComponent },
  //{ path: ':system/c/:idCustomer/:idOrderType', component: MainComponent },
  //{ path: ':system/payresult/:reference', component: PayResultComponent },
  //{ path: ':system/kvittering/:reference', component: KvitteraComponent },
  //{ path: ':system/check', component: CheckComponent },
  //{ path: ':system/update-address/:idOrder', component: UpdateAddressComponent },
  //{ path: ':system/:link', component: MainComponent },
  
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
