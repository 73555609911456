<div class="mrouter">

    <div class="tc">
        <div class="logo">
            <a [href]="logoUrl"><img class="logoimg" [src]="logoImageUrl" style="width: 75%; max-width: 300px;"></a>
        </div>
        <div class="fs22" style="padding-bottom: 25px">Registrera Säljare</div>
        <div class="fs23">{{project?.name}}</div>
    </div>

    <div *ngIf="step == 1">

        <form>

            <!-- team -->
            <div *ngIf="teams.length" class="section">
                <mat-form-field class="ff">
                    <mat-label>Lag/klass</mat-label>
                    <mat-select [(value)]="member.idTeam" (selectionChange)="teamChange($event)">
                        <mat-option *ngFor="let team of teams" [value]="team.id">
                          {{team.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <!-- First Name-->
            <div class="section">
                <mat-form-field class="ff"><input matInput [placeholder]="'Förnamn'" name="firstName" [(ngModel)]="member.firstName" maxlength="40"></mat-form-field>
            </div>
    
            <!-- Last Name-->
            <div class="section">
                <mat-form-field class="ff"><input matInput [placeholder]="'Efternamn'" name="lastName" [(ngModel)]="member.lastName" maxlength="40"></mat-form-field>
            </div>
    
            <!-- Email -->
            <div class="section">
                <mat-form-field class="ff"><input matInput [placeholder]="'E-post'" name="email" [(ngModel)]="member.email" maxlength="40"></mat-form-field>
            </div>
    
            <!-- Email -->
            <div class="section">
                <mat-form-field class="ff"><input matInput [placeholder]="'Telefon'" name="phone" [(ngModel)]="member.phone" maxlength="40"></mat-form-field>
            </div>
    
            <div class="section">
                <button style="width:160px" type="submit" mat-stroked-button color="primary" (click)="onSubmit($event)">Registrera</button>
            </div>

        </form>
        <!--
        <dx-validation-group>

            <div *ngIf="teams.length" class="dx-field">
                <dx-select-box
                    label="Lag" labelMode="floating"
                    width="100%"
                    [(value)]="selectedTeamId"
                    [dataSource]="teams" 
                    valueExpr="id" 
                    displayExpr="name" 
                    (onValueChanged)="changeTeam($event)">
                </dx-select-box>            
            </div> 

            <div class="dx-field">
                <dx-text-box [(value)]="member.firstName" label="Förnamn" labelMode="floating" width="100%">
                    <dx-validator>
                        <dxi-validation-rule type="required" message="Måste anges"></dxi-validation-rule>
                    </dx-validator>
                </dx-text-box>
            </div> 

            <div class="dx-field">
                <dx-text-box [(value)]="member.lastName" label="Efternamn" labelMode="floating" width="100%">
                </dx-text-box>
            </div> 

            <div class="dx-field">
                <dx-text-box [(value)]="member.email" label="E-post" labelMode="floating" width="100%">
                    <dx-validator>
                        <dxi-validation-rule type="required" message="Måste anges"></dxi-validation-rule>
                        <dxi-validation-rule type="email" message="Felaktig E-post"></dxi-validation-rule>
                    </dx-validator>
                </dx-text-box>
            </div> 

            <div class="dx-field">
                <dx-text-box [(value)]="member.phone" label="Telefonnummer" labelMode="floating" width="100%">
                </dx-text-box>
            </div> 
            
            <div class="dx-field">
                <dx-button type="default" text="Registrera" (onClick)="onSubmit($event)" width="100%"></dx-button>
            </div>

        </dx-validation-group>
        -->

        <!--
        {{selectedTeamId}}
        <pre>{{selectedTeam|json}}</pre>
        -->

    </div>

    <div *ngIf="step == 2">

        <div class="dx-field box">
            Tack!<br><br>Registreringen har genomförts och vi kommer att skicka mail med länk till försäljningssidan inom kort.<br><br>
            Vänliga hälsningar<br>Foreningskryddor.se
        </div>

    </div>


</div>
