<!-- Client Messages -->
<div *ngFor="let cm of db.clientMessages" class="cm">{{cm.message}}</div>

<div class="total" [style.font-family]="db.fonts[1]" [style]="1025 | customStyle : db.stylePipeHelper">
  
    <div class="tr cp" (click)="close()">
      <fa-icon [icon]="faTimes" size="1x" class="closeicon"></fa-icon>
    </div>
    
    <!-- My order -->
    <div class="fat">{{ 214 | l : lang.ph }}</div>

    <!-- Cart rows-->
    <div *ngFor="let cp of db.productPipeHelper | cartParents" class="row">

      <div class="parentrow">
        
        <div class="row1">
          <span *ngIf="!cp.isTip">{{cp.quantity}} {{ cp.unit ? cp.unit : (215 | l : lang.ph) }} </span>
          <span>{{ cp | lang : 'name' : lang.ph }}</span>
        </div>
        
        <div *ngIf="cp.price" class="row2">{{ cp.totalAmount | number : lang.numberFormat : lang.activeLocale }} {{db.configuration.currency}}</div>

        <!-- Product discount, for debugging only
        <div class="row2" style="padding: 0 10px;">{{ cp.discountAmount | number : lang.numberFormat : lang.activeLocale }} {{db.configuration.currency}}</div>
        -->

        <div class="row2 cp" (click)="delItem(cp)">
          <fa-icon [icon]="faTimes" size="1x" class="removeicon"></fa-icon>
        </div>
      
      </div>

      <!-- Addon rows -->
      <div *ngFor="let addon of cp.id | cartChildren" class="childrow" style="padding-left: 20px;">

        <div class="row1">
          <span>-- </span>
          <span *ngIf="addon.quantity > 1">{{addon.quantity}} {{ 215 | l : lang.ph }} </span>
          <span>{{ addon | lang : 'name' : lang.ph }}</span>
        </div>

        <div class="row2" style="margin-right: 18px;">
          <div *ngIf="addon.price" class="row2">{{ addon.price * addon.quantity | number : lang.numberFormat : lang.activeLocale }} {{db.configuration.currency}}</div>
        </div>

      </div>

    </div>

    <!-- Totals -->
    <div style="text-align: right;margin-top: 10px; margin-right: 28px">---------</div>

    <!-- Discount code -->
    <div *ngIf="db.cart.discountCode && (db.cart.discountCode.amount || db.cart.discountCode.percentage)" style="display: flex; justify-content: space-between; margin-right: 28px; padding: 10px 0;">
      <div class="fat">{{ 383 | l : lang.ph }} {{db.cart.discountCode.code}}</div>
      <div class="fat">
        <span *ngIf="db.cart.discountCode.amount">{{ db.cart.discountCode.amount | number : lang.numberFormat : lang.activeLocale }} {{db.configuration.currency}}</span>
        <span *ngIf="db.cart.discountCode.percentage">{{ db.cart.discountCode.percentage | number : lang.numberFormat : lang.activeLocale }} %</span>
      </div>
    </div>

    <div style="display: flex; justify-content: space-between; margin-right: 28px;">
        <div class="fat">{{ 216 | l : lang.ph }}</div>
        <div class="fat">{{ db.productPipeHelper | cartTotal | number : lang.numberFormat : lang.activeLocale }} {{db.configuration.currency}}</div>
    </div>

    <!-- Share cart -->
    <div *ngIf="db.system == 'demo1'" class="sharediv">
      <div class="share1">
        <button mat-stroked-button class="sharebtn" (click)="shareCart()">Share cart</button>
      </div>
      <div class="share2"> Click to share cart with someone at your table</div>
    </div>    


    <!--
    <pre>{{db.cartProducts|json}}</pre>
    -->

    <!-- Tip -->
    <div *ngIf="db.orderType.useTip" class="tipdiv">
      <button mat-stroked-button class="tipbtn" (click)="showTip = !showTip">{{ 223 | l : lang.ph }}</button>
    </div>    

    <div *ngIf="showTip && (db.tipPercentages.length || db.tipAmounts.length)" style="margin: 10px 0;">

      <div *ngIf="db.tipPercentages.length">
        <button *ngFor="let p of db.tipPercentages" mat-stroked-button class="tipbtn" (click)="tip(p, 0)">{{p}} %</button>
      </div>
      
      <div *ngIf="db.tipAmounts.length" style="margin-top: 12px">
        <button *ngFor="let a of db.tipAmounts" mat-stroked-button class="tipbtn" (click)="tip(a, 1)">{{a}} {{db.configuration.currency}}</button>
      </div>
      
    </div>

    <!-- Discount codes -->
    <div *ngIf="db.orderType.allowDiscountCodes" style="margin: 20px 0;">

      <button mat-stroked-button class="tipbtn" (click)="showDC = !showDC">{{ 383 | l : lang.ph }}</button>

      <div *ngIf="showDC" class="v2">
        <form #dcform="ngForm" (ngSubmit)="addDiscountCode(dcform.value)">
          <mat-form-field style="display: inline-block; margin-right: 20px;">
            <input matInput [placeholder]="'Rabattkod'" name="code" [ngModel]="discountCode" required minlength="2">
          </mat-form-field>
          <button type="submit" mat-stroked-button color="primary" [disabled]="!dcform.valid">{{ 159 | l : lang.ph }}</button>
        </form>
      </div>
      
    </div>

    <!-- Delivery date and time -->
    <div *ngIf="db.orderType.type < 2" class="deliverytime">{{ 317 | l : lang.ph }}: {{db.cart.deliveryDate}} {{db.cart.deliveryTime}}</div>

    <!-- Delivery address -->
    <div *ngIf="db.deliveryAddress">
      <div>{{ 348 | l : lang.ph }}: {{db.deliveryAddress.name}}</div>
      <!--<div>
        <span>{{db.deliveryAmount}} {{ db.idLanguage | l : '7' }}</span>
      </div>-->
      <div *ngIf="db.cart.deliveryInstructions" style="padding: 10px 0">{{db.cart.deliveryInstructions}}</div>
    </div>

    <!-- Team sales -->
    <div *ngIf="db.configuration.teamSales" style="margin: 30px 0;">

      <!-- Teams -->
      <mat-form-field *ngIf="db.teams.length" appearance="fill" style="width: 100%">
        <mat-label>Välj lag/klass</mat-label>
        <mat-select [(value)]="db.cart.idTeam" (selectionChange)="teamChange($event)">
          <mat-option *ngFor="let team of db.teams" [value]="team.id">
            {{team.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <!-- Members -->
      <mat-form-field *ngIf="db.cart.idTeam || !db.teams.length" appearance="fill" style="width: 100%">
        <mat-label>Välj säljare</mat-label>
        <mat-select [(value)]="db.cart.idMember" (selectionChange)="memberChange($event)">
          <mat-option *ngFor="let member of db.members | teamMembers : db.cart.idTeam : pipeHelper" [value]="member.id">
            {{member.firstName}} {{member.lastName}}
          </mat-option>
        </mat-select>
      </mat-form-field>      
      
      
    </div>

    <!-- Cart text-->
    <div class="carttext" *ngIf="db.orderType.description2" [innerHTML]="db.orderType.description2 | sanitizeHtml"></div>

    <!-- Input form -->
    <div style="padding: 20px 0;">
      
      <form #f="ngForm" [formGroup]="userForm" (ngSubmit)="pay(f.value)">

        <!-- Company Name-->
        <mat-form-field *ngIf="db.addressFields.includes('companyName')" class="ff"><input matInput [placeholder]="'Företagsnamn'" formControlName="companyName" maxlength="25"></mat-form-field>

        <!-- Business Id-->
        <mat-form-field *ngIf="db.addressFields.includes('businessId')" class="ff"><input matInput [placeholder]="'Organisationsnummer'" formControlName="businessId" maxlength="15"></mat-form-field>

        <!-- First Name-->
        <mat-form-field class="ff"><input matInput [placeholder]="217 | l : lang.ph" formControlName="firstName" maxlength="20"></mat-form-field>

        <!-- Last Name -->
        <mat-form-field class="ff"><input matInput [placeholder]="218 | l : lang.ph" formControlName="lastName"  maxlength="25"></mat-form-field>

        <!-- Phone  -->
        <mat-form-field class="ff" *ngIf="db.system != 'pappasparlor'">
          <ngx-mat-intl-tel-input
            [cssClass]="'custom'"
            [preferredCountries]="preferredCountries"
            [enablePlaceholder]="true"
            [placeholder]="220 | l : lang.ph"
            name="phone"
            formControlName="phone" #phone></ngx-mat-intl-tel-input>
          <mat-hint>e.g. {{phone.selectedCountry.placeHolder}}</mat-hint>
          <mat-error *ngIf="f.form.controls['phone']?.errors?.required">Required Field</mat-error>
          <mat-error *ngIf="f.form.controls['phone']?.errors?.validatePhoneNumber">Invalid Number</mat-error>
        </mat-form-field>    
        
        <mat-form-field class="ff" *ngIf="db.system == 'pappasparlor'"><input matInput [placeholder]="220 | l : lang.ph" formControlName="phone"  maxlength="25"></mat-form-field>

        <!-- Mail -->
        <mat-form-field class="ff"><input matInput [placeholder]="219 | l : lang.ph" formControlName="email" maxlength="50"></mat-form-field>

        <!-- Address 1 -->
        <mat-form-field *ngIf="db.addressFields.includes('address1') || cartHasGiftCard" class="ff">
          <input matInput [placeholder]="351 | l : lang.ph" formControlName="address1" maxlength="40" required>
        </mat-form-field>

        <!-- Address 2 -->
        <mat-form-field *ngIf="db.addressFields.includes('address2') || cartHasGiftCard" class="ff">
          <input matInput [placeholder]="352 | l : lang.ph" formControlName="address2" maxlength="40">
        </mat-form-field>

        <!-- Post code -->
        <mat-form-field *ngIf="db.addressFields.includes('postcode') || cartHasGiftCard" class="ff">
          <input matInput [placeholder]="353 | l : lang.ph" formControlName="postCode" maxlength="40" required>
        </mat-form-field>

        <!-- City -->
        <mat-form-field *ngIf="db.addressFields.includes('city') || cartHasGiftCard" class="ff">
          <input matInput [placeholder]="354 | l : lang.ph" formControlName="city" maxlength="40" required>
        </mat-form-field>

        <!-- Country -->
        <mat-form-field *ngIf="db.addressFields.includes('country')" class="ff">
          <input matInput [placeholder]="'Country'" formControlName="country" maxlength="40" required>
        </mat-form-field>

        <!-- Additional info -->
        <mat-form-field *ngIf="db.configuration.orderInfo" class="ff">
            <input matInput [placeholder]="221 | l : lang.ph" formControlName="info" maxlength="100">
        </mat-form-field>

        <!-- Team delivery -->
        <div *ngIf="db.directDeliveryProduct" style="margin: 20px 0;">
          <mat-checkbox formControlName="teamDelivery" (change)="teamDeliveryChange($event)">Leverans sker kostnadsfritt via föreningen/klassen du beställt av.</mat-checkbox>
        </div>    

        <!-- Direct delivery -->
        <div *ngIf="db.directDeliveryProduct" style="margin: 20px 0;">
          <mat-checkbox formControlName="directDelivery" (change)="directDeliveryChange($event)">{{db.directDeliveryProduct.name}}</mat-checkbox>
          <div>{{db.directDeliveryProduct.description}}</div>
        </div>    

        <!-- Accept terms -->
        <div style="margin: 20px 0;">
          <mat-checkbox formControlName="termsAccepted">{{ 222 | l : lang.ph }}</mat-checkbox>
        </div>    

        <!-- Payment buttons  -->
        <div style="text-align: center" *ngIf="db.orderType.paymentTypes">

          <div *ngFor="let pt of db.orderType.paymentTypes | active | payButtons : db.productPipeHelper" style="margin: 20px 0">
            <button style="width:160px" type="submit" mat-stroked-button color="primary" (click)="setPayMethod(pt)" [disabled]="!(userForm.status == 'VALID') || disableForm">{{ pt.idPaymentType | subObj : db.paymentTypes | lang : 'name' : lang.ph }}</button>
          </div>
    
        </div>        

      </form>
  
    </div>    

      <!--<pre>{{db.cart.products|json}}</pre>-->

</div>

<ng-template #shareRef>

  <div style="text-align: right;" class="closex">
      <button mat-icon-button class="close-button" [mat-dialog-close]="true">
          <mat-icon class="close-icon" color="warn">close</mat-icon>
      </button>
  </div>  

  <mat-dialog-content>
      <div style="text-align: center;"><h2>Sharing is caring</h2></div>
      <div class="sharetext"><p>Share your cart to table neighbours by clicking the button below. Perfect for families and friends dining together, all items are sent to the kitchen as one order.</p><p>The person who picks up your shared items also pays for them.</p></div>
  </mat-dialog-content>

  <div mat-dialog-actions>
      <div style="margin: 10px auto">
          <button mat-raised-button mat-dialog-close color="primary">SHARE</button>
      </div>
  </div>

</ng-template>

<!-- Giftcard popup template -->
<ng-template #giftcardRef>

  <div style="text-align: right;" class="closex">
    <button mat-icon-button class="close-button" [mat-dialog-close]="true">
        <mat-icon class="close-icon" color="warn">close</mat-icon>
    </button>
  </div>  

  <mat-dialog-content>
    <div style="text-align: center;"><h2>{{394 | l : lang.ph}}</h2></div>
    <div class="sharetext"><p>{{397 | l : lang.ph}}</p></div>
    <div class="gcinputdiv"><input matInput [placeholder]="" name="giftcardCode" [(ngModel)]="giftcardCode"></div>
  </mat-dialog-content>

  <div mat-dialog-actions>
    <div style="margin: 10px auto">
        <button mat-raised-button mat-dialog-close color="primary">{{159 | l : lang.ph}}</button>
    </div>
  </div>  

</ng-template>

<!-- https://stackblitz.com/edit/angular-ngx-mat-intl-tel-input?file=src%2Fapp%2Fapp.component.html -->

<ng-template #oldform>

  <form #userform="ngForm" (ngSubmit)="pay(userform.value)">
  
    <mat-form-field style="display: block">
      <input matInput [placeholder]="217 | l : lang.ph" name="firstName" [(ngModel)]="db.cart.firstName" required minlength="2" [disabled]="disableForm" maxlength="20">
    </mat-form-field>

    <mat-form-field style="display: block">
      <input matInput [placeholder]="218 | l : lang.ph" name="lastName" [(ngModel)]="db.cart.lastName" required minlength="2" [disabled]="disableForm" maxlength="25">
    </mat-form-field>
    <!--
    <mat-form-field *ngIf="lang.defaultLanguageCode == 'es'" style="display: block">
      <input matInput [placeholder]="220 | l : lang.ph" name="phone" [(ngModel)]="db.cart.phone" [disabled]="disableForm">
    </mat-form-field>
    -->
    <mat-form-field style="display: block">
      <ngx-mat-intl-tel-input
      [preferredCountries]="['se', 'dk']"
      [enablePlaceholder]="true"
      name="phone"
      [(ngModel)]="db.cart.phone"></ngx-mat-intl-tel-input>         
    </mat-form-field>

    <!--
    <mat-form-field *ngIf="lang.defaultLanguageCode != 'es'" style="display: block">
      <input matInput [placeholder]="220 | l : lang.ph" name="phone" [(ngModel)]="db.cart.phone" required minlength="10" maxlength="10" pattern="[0-9]*" [disabled]="disableForm">
    </mat-form-field>
    -->

    <mat-form-field style="display: block">
      <input matInput [placeholder]="219 | l : lang.ph" name="email" [(ngModel)]="db.cart.email" required email [disabled]="disableForm" maxlength="50">
    </mat-form-field>

    <!-- Address fields -->
    <mat-form-field *ngIf="db.addressFields.includes('address1')" style="display: block">
      <input matInput [placeholder]="'Address 1'" name="address1" [(ngModel)]="db.cart.address1" [disabled]="disableForm" maxlength="40" required>
    </mat-form-field>

    <mat-form-field *ngIf="db.addressFields.includes('address2')" style="display: block">
      <input matInput [placeholder]="'Address 2'" name="address2" [(ngModel)]="db.cart.address2" [disabled]="disableForm" maxlength="40">
    </mat-form-field>

    <mat-form-field *ngIf="db.addressFields.includes('postcode')" style="display: block">
      <input matInput [placeholder]="'Postcode'" name="postCode" [(ngModel)]="db.cart.postCode" [disabled]="disableForm" maxlength="40" required>
    </mat-form-field>

    <mat-form-field *ngIf="db.addressFields.includes('city')" style="display: block">
      <input matInput [placeholder]="'City'" name="city" [(ngModel)]="db.cart.city" [disabled]="disableForm" maxlength="40" required>
    </mat-form-field>

    <mat-form-field *ngIf="db.addressFields.includes('country')" style="display: block">
      <input matInput [placeholder]="'Country'" name="country" [(ngModel)]="db.cart.country" [disabled]="disableForm" maxlength="40" required>
    </mat-form-field>

    <!-- Additional info -->
    <mat-form-field *ngIf="db.configuration.orderInfo" style="display: block">
        <input matInput [placeholder]="221 | l : lang.ph" name="info" [(ngModel)]="db.cart.info" [disabled]="disableForm" maxlength="100">
    </mat-form-field>      

    <!-- Accept terms
    <div style="margin: 20px 0;">
      <mat-checkbox name="termsAccepted" [(ngModel)]="termsAccepted" [disabled]="disableForm">{{ 222 | l : lang.ph }}</mat-checkbox>
    </div> -->

    <!-- Spain invoice 
    <div *ngIf="lang.defaultLanguageCode == 'es'" style="margin: 20px 0;">
      <mat-checkbox name="spainInvoice" [(ngModel)]="spainInvoice" [disabled]="disableForm">{{ 390 | l : lang.ph }}</mat-checkbox>
    </div>-->

    <!-- Spain razonSocial 
    <mat-form-field *ngIf="spainInvoice" style="display: block">
      <input matInput [placeholder]="391 | l : lang.ph" name="razonSocial" [(ngModel)]="db.cart.razonSocial" [disabled]="disableForm">
    </mat-form-field>      -->    

    <!-- Spain nif 
    <mat-form-field *ngIf="spainInvoice" style="display: block">
      <input matInput [placeholder]="392 | l : lang.ph" name="nif" [(ngModel)]="db.cart.nif" [disabled]="disableForm">
    </mat-form-field>    -->      

    <!-- Spain domicilioSocial 
    <mat-form-field *ngIf="spainInvoice" style="display: block">
      <input matInput [placeholder]="393 | l : lang.ph" name="domicilioSocial" [(ngModel)]="db.cart.domicilioSocial" [disabled]="disableForm">
    </mat-form-field>        -->      


    <!-- Show cash payment if no payment types-->
    <div style="text-align: center" *ngIf="!db.orderType.paymentTypes">
      
      <div style="margin: 20px 0">
        <button style="width:160px" type="submit" mat-stroked-button color="primary" (click)="setPayMethod(1)" [disabled]="!userform.valid || disableForm">{{ 264 | l : lang.ph }}</button>
      </div>

      <!--
      <div style="margin: 20px 0">
        <button style="width:160px" type="submit" mat-stroked-button color="primary" (click)="setPayMethod(3)" [disabled]="!userform.valid || disableForm">Card & Wallet Pay</button>
      </div>
      -->

      <!--
      <div style="margin: 20px 0">
        <button style="width:160px" type="submit" mat-stroked-button color="primary" (click)="setPayMethod(1)" [disabled]="!userform.valid || disableForm">Pay by Credit Card</button>
      </div>
      -->
      
    </div>

    <div style="text-align: center" *ngIf="db.orderType.paymentTypes">

      <div *ngFor="let pt of db.orderType.paymentTypes | active | payButtons : db.productPipeHelper" style="margin: 20px 0">
        <button style="width:160px" type="submit" mat-stroked-button color="primary" (click)="setPayMethod(pt)" [disabled]="!userform.valid || disableForm">{{ pt.idPaymentType | subObj : db.paymentTypes | lang : 'name' : lang.ph }}</button>
      </div>

    </div>

  </form>

</ng-template>