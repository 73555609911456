import { Pipe, PipeTransform } from '@angular/core';
import { Product } from '../interfaces'

@Pipe({
  name: 'prodpipe'
})
export class ProdpipePipe implements PipeTransform {

  //transform(products: any[], selCatProdIds: any[], pipeHelper: number): any[] {
  //  return products.filter(p => selCatProdIds.includes(p.id))
  //}

  transform(products: Product[], selCatProdIds: any[], pipeHelper: number): Product[] {

    let r = []

    selCatProdIds.forEach(spid => {
      let p = products.find(p => p.id == spid)
      if (p && p.active && !p.blockedTo) r.push(p)
    })

    return r

  }

}
