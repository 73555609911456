import { Component, OnInit } from '@angular/core';
import { DbService } from '../../services'
import { Subscription } from 'rxjs'
import { ActivatedRoute } from '@angular/router'
import { Order } from '../../interfaces';
import * as moment from 'moment'

@Component({
  selector: 'app-kvittera',
  templateUrl: './kvittera.component.html',
  styleUrls: ['./kvittera.component.scss']
})
export class KvitteraComponent implements OnInit {

  routeSubscription: Subscription
  reference = ''
  order: Order
  step = 0
  btnText = 'Kvittera'

  constructor(public db: DbService, private route: ActivatedRoute) {

    this.routeSubscription = this.route.params.subscribe(params => {

      if (params.system) this.db.system = params.system
      if (params.reference) this.reference = params.reference

      if (this.db.system && this.reference) this.setup()

    })

  }

  ngOnInit(): void {
  }

  async setup() {

    this.db.configuration = await <any>this.db.httpPost('findone', { system: this.db.system, table: 'configuration', condition: { id: '1' }, token: this.db.token })
    
    this.db.setBg(this.db.configuration.idBackground)

    let orderNumber = (+this.reference.substring(1) / 7 - 17) / 3
    //console.log('Ordernumer', orderNumber)

    this.order = await <any>this.db.httpPost('findone', { system: this.db.system, table: 'orders', condition: { orderNumber: orderNumber, delivered: false, orderDate: moment().format('YYYY-MM-DD') }, token: this.db.token })
    if (!this.order) return this.step = 2

    console.log(this.order)
    this.step = 1

  }

  async kvittera() {

    this.btnText = 'Kvitterad!'
    this.order.delivered = true
    
    this.order.products.forEach(op => {
      op.delivered = true
    })

    let result = await this.db.httpPost('setdelivered', { system: this.db.system, idOrder: this.order.id, idOrderProduct: '*', delivered: true })
   
  }

}
