import { Component, OnInit } from '@angular/core';
import { DbService, LangService } from '../../services'

//declare var Dibs: any

@Component({
  selector: 'app-step110',
  templateUrl: './step110.component.html',
  styleUrls: ['./step110.component.scss']
})
export class Step110Component implements OnInit {

  constructor(public db: DbService, public lang: LangService) { }

  ngOnInit(): void {

    console.log('-->', this.db.adyenRequest)

    window.location.assign(this.db.adyenRequest.hostedPaymentPageUrl)

//    let checkoutOptions = {
//      checkoutKey: 'test-checkout-key-7cbeacfdff0b4f76964ddb6ddb1ab512',
//      paymentId : this.db.adyenRequest.paymentId,
//      containerId : "dibs-complete-checkout", 
//      language: "en-GB", // [Optional] Default value: en-GB
//    }
//
//    console.log('checkoutOptions', checkoutOptions)
//
//  var checkout = new Dibs.Checkout(checkoutOptions);
  }

}
